import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {forkJoin, map, Observable, of, switchMap, take, takeUntil, tap} from 'rxjs';
import {Action, BaseViewComponent} from 'src/app/common/components/base-view.component';
import {AircraftGroup} from 'src/app/features/administration/models/aircraftGroup';
import {AircraftGroupService} from 'src/app/features/administration/services/aircraft-group.service';
import {TeledynePackagesService} from 'src/app/features/sendtoteledyne/services/teledynepackages.service';
import {SecurityUserService} from 'src/app/security/services/security-user.service';
import {BreadcrumbService} from '../../../../../common/services/breadcrumb.service';
import {CommonService} from '../../../../../common/services/common.service';
import {Components} from '../../integration/datadelivery.components';
import {permissions} from '../../integration/datadelivery.permissions';
import {
  ClientPackages,
  ConversionType,
  EndPoints,
  Redaction,
  ResponseModelClientPackages,
  ZipType,
  aircrafts, flightDataType, DataType,
} from '../../models/ClientPackage';
import {ClientPackagesService} from '../../services/clientPackage.service';
import {DataDeliveryEndPointService} from '../../services/endpoint.service';
import {FlightDataRedactionService} from '../../services/flightDataRedaction.service';

@Component({
  selector: 'app-clientpackage-add',
  templateUrl: './clientpackage-add.component.html',
  styleUrls: ['./clientpackage-add.component.css'],
})
export class ClientpackageAddComponent
  extends BaseViewComponent
  implements OnInit {
  pageTitle = Components.AddClientPackages.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  clientpackages: ClientPackages[] = [];
  clientpackage!: ClientPackages;
  redactionType: Redaction[] = [];
  zipType: ZipType[] = [];
  flightdataType: flightDataType[] = [];
  endpoints: EndPoints[] = [];
  conversionType: ConversionType[] = [];
  availableEndPoints: EndPoints[];
  availableAircraft: aircrafts[] = [];
  selectedEndPoints: EndPoints[] = [];
  selectedAircraft: aircrafts[] = [];
  selectedEndPointsIDs: number[] = [];
  selectedAircraftIDs: number[] = [];
  responseModelClientPackages: ResponseModelClientPackages;
  pickListChange = false;
  pickAircraftlistChange = false;
  isButtonDisabled = true;
  isAircraftButtonDisabled = true;
  initialEndpointsCount: number;
  initialAircraftsCount: number;
  saveButtonDisabled: boolean;
  isReadonly = true;
  messageLabel = 'Client Package';
  showButtons = true;
  selectedRedactionId: number;
  defaultfileName: string;
  isRedaction = true;
  noConversionId = 1;
  drpdownindex = 0;
  file_Name = `%Reg%%Source%%YYYY%%MM%%DD%%HH%%mm%%ss%`;
  newLabel = "New Client Package";
  aircraftGroups: SelectItem[];
  selectedAircraftGroupIDs: aircrafts[] = [];
  targetAircraft: aircrafts[] = [];
  selectedAircraftGroup: number;
  selectedTargetAircraftGroup: number;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private datadeliveryEndPointservice: DataDeliveryEndPointService,
    private clientPackagesService: ClientPackagesService,
    private flightDataRedactionService: FlightDataRedactionService,
    private commonService: CommonService,
    private realUserService: SecurityUserService,
    private aircraftGroupService: AircraftGroupService,
    private teledynePackagesService: TeledynePackagesService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.ClientPackages.label,
        routerLink: Components.ClientPackages.path,
      },
      {
        label:
          Components.AddClientPackages.label +
          ` (${this.route.snapshot.queryParamMap.get('name')})`,
      },
    ]);
  }

  intialVal() {
    this.isNew = true;
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.clientpackage.package_id = -1;
  }

  ngOnInit() {
    // Initialize permissions and services
    this.getBreadCrums();
    this.checkPermissions();

    // Initialize the client package
    this.clientpackage = new ClientPackages();

    const packageId = +this.route.snapshot.queryParamMap.get('packageId');
    const isClone = !!this.route.snapshot.queryParamMap.get('clone');

    // Determine read-only and button visibility states
    this.isReadonly = !this.canEdit;
    this.showButtons = this.canEdit;

    if (packageId) {
      this.isNew = isClone;

      if (isClone) {
        this.pageTitle = 'Clone Client Package';
        this.updateBreadcrumb(`${this.pageTitle} (${this.route.snapshot.queryParamMap.get('name')})`);
      }

      this.loadExistingPackage(packageId, isClone);
    } else {
      this.intialVal();
      this.loadNewPackage();
    }
  }

  checkPermissions() {
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.create
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.manage
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_client_packages.delete
    );
  }

  loadExistingPackage(packageId: number, clone: boolean) {
    this.loadingCount++;

    this.fetchData(packageId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: results => {
        this.processExistingPackageResults(results, clone);
        this.processEndpoints(results);
        this.processAircrafts(results);
        this.updateUI(clone);
      },
      error: error => {
        this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
      }
    });
  }

  loadNewPackage() {
    this.clientpackage.aircrafts = [];
    this.clientpackage.endpoints = [];
    this.conversionType = [];
    this.availableEndPoints = [];
    this.availableAircraft = [];
    this.zipType = [];
    this.loadingCount++;
    this.fetchData(null).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: results => this.processNewPackageResults(results),
      error: error => {
        this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
      }
    });
  }

  private fetchData(packageId: any): Observable<any> {
    if (packageId != null) {
      return forkJoin({
        getPackages: this.clientPackagesService.getClientPackage_by_id(packageId),
        avialableEndPoints: this.datadeliveryEndPointservice.getEndPoints(),
        availableConvertionType: this.clientPackagesService.getConvertionTypes(),
        ziptypes: this.flightDataRedactionService.getZipTypes(),
        aircraftgroups: this.aircraftGroupService.listAircraftGroups(),
        availableAircraft: this.commonService.listAircraftGroupIdFilters(0)
      });
    } else {
      return forkJoin({
        avialableEndPoints: this.datadeliveryEndPointservice.getEndPoints(),
        availableConvertionType: this.clientPackagesService.getConvertionTypes(),
        redactionType: this.flightDataRedactionService.getRedactionType(),
        ziptypes: this.flightDataRedactionService.getZipTypes(),
        aircraftgroups: this.aircraftGroupService.listAircraftGroups(),
        getaircraft: this.commonService.listAircraftGroupIdFilters(0)
      })
    }
  }

  private processNewPackageResults(results) {
    this.availableEndPoints = [];
    this.availableAircraft = [];
    this.zipType = [];

    results.ziptypes.forEach(ele => {
      this.zipType.push({ziptype: ele.ziptype, ziptype_id: ele.ziptype_id})
    });
    results.avialableEndPoints.forEach(ele => {
      this.availableEndPoints.push({endpoint_id: ele.endpoint_id, endpoint_name: ele.name})

    });
    this.conversionType = results.availableConvertionType;
    this.aircraftGroups = this.teledynePackagesService.populateDropdownItems<AircraftGroup>(results.aircraftgroups, 'aircraftgroup_name', 'aircraftgroup_id');

    results.getaircraft.forEach((ele) => {
      if (!this.availableAircraft.find(a => a.aircraft_registration == ele.aircraft_registration)) {
        this.availableAircraft.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_id: ele.aircraft_id,
          aircraft_registration: ele.aircraft_registration,
        });
      }
    });
    this.initialEndpointsCount = 0;
    this.initialAircraftsCount = 0;
    this.clientpackage.file_name = this.file_Name;
    this.availableAircraft.sort((a, b) => {
      return a.aircraft_registration.localeCompare(b.aircraft_registration)
    });
    this.availableEndPoints.sort((a, b) => {
      return a.endpoint_name.localeCompare(b.endpoint_name)
    });
  }

  private processExistingPackageResults(results: any, clone: boolean) {
    this.zipType = results.ziptypes;
    this.loadRedactionType(results.getPackages.conversiontype_id, results.getPackages);
    this.clientpackage = results.getPackages;
    this.clientpackage.primary = results.getPackages.primary == true;
    this.clientpackage.secondary = results.getPackages.secondary == true;
    this.isRedaction = this.clientpackage.conversiontype_id != this.noConversionId;
    this.conversionType = results.availableConvertionType;
  }

  processEndpoints(results: any): void {
    // Initialize arrays to hold available and selected endpoints
    this.availableEndPoints = [];
    this.selectedEndPoints = [];

    // Filter unique endpoints from clientpackage and update selectedEndPoints
    const uniqueEndpoints = new Map<number, { endpoint_id: number, endpoint_name: string }>();

    this.clientpackage.endpoints.forEach(endpoint => {
      if (!uniqueEndpoints.has(endpoint.endpoint_id)) {
        uniqueEndpoints.set(endpoint.endpoint_id, {
          endpoint_id: endpoint.endpoint_id,
          endpoint_name: endpoint.endpoint_name
        });
      }
    });

    // Convert map values to array and assign it to selectedEndPoints
    this.selectedEndPoints = Array.from(uniqueEndpoints.values());

    // Update clientpackage endpoints with the filtered selectedEndPoints
    this.clientpackage.endpoints = this.selectedEndPoints;

    // Populate availableEndPoints from results
    this.availableEndPoints = results.avialableEndPoints.map(endpoint => ({
      endpoint_id: endpoint.endpoint_id,
      endpoint_name: endpoint.name
    }));

    // Populate selectedEndPointsIDs with the IDs of selected endpoints
    this.selectedEndPointsIDs = this.selectedEndPoints.map(endpoint => endpoint.endpoint_id);

    // Filter out endpoints in availableEndPoints that are already selected
    this.availableEndPoints = this.availableEndPoints.filter(endpoint =>
      !this.selectedEndPoints.some(selected => selected.endpoint_id === endpoint.endpoint_id)
    );

    // Store the count of selected endpoints
    this.initialEndpointsCount = this.selectedEndPoints.length;

    // Sort availableEndPoints and clientpackage endpoints by endpoint_name
    this.availableEndPoints.sort((a, b) => a.endpoint_name.localeCompare(b.endpoint_name));
    this.clientpackage.endpoints.sort((a, b) => a.endpoint_name.localeCompare(b.endpoint_name));
  }

  processAircrafts(results: any): void {
    // Populate aircraftGroups dropdown items from results using a service
    this.aircraftGroups = this.teledynePackagesService.populateDropdownItems<AircraftGroup>(
      results.aircraftgroups,
      'aircraftgroup_name',
      'aircraftgroup_id'
    );

    // Initialize arrays for target and selected aircraft
    this.targetAircraft = [];
    this.selectedAircraft = [];

    // Populate selectedAircraft with aircraft from clientpackage
    const selectedAircraftMap = new Map<string, { aircraft_id: number, aircraft_registration: string }>();
    this.clientpackage.aircrafts.forEach(aircraft => {
      selectedAircraftMap.set(aircraft.aircraft_name, {
        aircraft_id: aircraft.aircraft_id,
        aircraft_registration: aircraft.aircraft_name
      });
    });
    this.selectedAircraft = Array.from(selectedAircraftMap.values());

    // Initialize a set for faster lookup
    const selectedAircraftRegistrationSet = new Set(this.selectedAircraft.map(a => a.aircraft_registration));

    // Process availableAircraft from results
    const availableAircraftMap = new Map<string, {
      aircraftgroup_id: number,
      aircraft_id: number,
      aircraft_registration: string
    }>();
    results.availableAircraft.forEach(aircraft => {
      const {aircraft_registration} = aircraft;
      if (selectedAircraftRegistrationSet.has(aircraft_registration)) {
        if (!this.targetAircraft.find(a => a.aircraft_registration === aircraft_registration)) {
          this.targetAircraft.push({
            aircraftgroup_id: aircraft.aircraftgroup_id,
            aircraft_id: aircraft.aircraft_id,
            aircraft_registration: aircraft_registration
          });
        }
      }
      if (!availableAircraftMap.has(aircraft_registration)) {
        availableAircraftMap.set(aircraft_registration, {
          aircraftgroup_id: aircraft.aircraftgroup_id,
          aircraft_id: aircraft.aircraft_id,
          aircraft_registration: aircraft_registration
        });
      }
    });

    // Convert availableAircraftMap to array
    this.availableAircraft = Array.from(availableAircraftMap.values());

    // Populate selectedAircraftGroupIDs with details from targetAircraft
    this.selectedAircraftGroupIDs = this.targetAircraft.map(aircraft => ({
      aircraft_id: aircraft.aircraft_id,
      aircraft_registration: aircraft.aircraft_registration,
      aircraftgroup_id: aircraft.aircraftgroup_id
    }));

    // Filter availableAircraft to remove those that are already selected
    this.availableAircraft = this.availableAircraft.filter(
      aircraft => !this.selectedAircraft.some(selected => selected.aircraft_registration === aircraft.aircraft_registration)
    );

    // Store the count of selected aircraft
    this.initialAircraftsCount = this.selectedAircraft.length;

    // Sort availableAircraft by aircraft_registration
    this.availableAircraft.sort((a, b) => a.aircraft_registration.localeCompare(b.aircraft_registration));
    this.selectedAircraft.sort((a, b) => a.aircraft_registration.localeCompare(b.aircraft_registration));
  }

  getPredindedRedaction(convertionTypeId: number) {
    this.loadingCount++;
    this.redactionType = [];
    forkJoin({
      predefinedDataType: this.clientPackagesService.getPredefinedRedaction(convertionTypeId),
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: results => {
        this.drpdownindex = 0;
          results.predefinedDataType.forEach((ele) => {
            this.drpdownindex = this.drpdownindex + 1;
            this.redactionType.push({
              redaction_id: ele.predefined_redaction_id,
              redaction_type: ele.name,
              isPredefined: true,
              redactiontypeId: this.drpdownindex,
            });
          });

          this.getRedactionType(
            convertionTypeId,
            this.drpdownindex,
            true,
            0,
            0
          );
      },
      error: error => {
        this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
      }
    })
  }

  getRedactionType(convertionTypeId: number, drpdownindex: number, isEdit: boolean, redaction_id: number, isRedaction: any) {
    this.loadingCount++;
    forkJoin({
      redactionType: this.clientPackagesService.getRedactionTypeDetails(convertionTypeId)
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: results => {
        results.redactionType.forEach(ele => {
          drpdownindex = drpdownindex + 1;
          this.redactionType.push({
            redaction_id: ele.redaction_id,
            redaction_type: ele.name,
            isPredefined: false,
            redactiontypeId: drpdownindex
          })
        });
        if (isEdit && isRedaction == null) {
          const getValue = this.redactionType.find(a => a.redaction_id == redaction_id && a.isPredefined == false);
          this.selectedRedactionId = getValue.redactiontypeId;
        }
      }, error: error => {
        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
      }
    })
  }

  loadRedactionType(convertionTypeId: number, data: ClientPackages) {
    this.loadingCount++;
    this.flightdataType = [];
    this.redactionType = [];
    forkJoin({
      predefinedDataType: this.clientPackagesService.getPredefinedRedaction(convertionTypeId)
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: results => {
        this.drpdownindex = 0;
        results.predefinedDataType.forEach(ele => {
          this.drpdownindex = this.drpdownindex + 1;
          this.redactionType.push({
            redaction_id: ele.predefined_redaction_id,
            redaction_type: ele.name,
            isPredefined: true,
            redactiontypeId: this.drpdownindex
          })
        })
        if (data.predefined_redaction_id != null) {
          const getValue = this.redactionType.find(a => a.redaction_id == data.predefined_redaction_id && a.isPredefined == true);
          this.selectedRedactionId = getValue.redactiontypeId;
        }
        this.getRedactionType(convertionTypeId, this.drpdownindex, true, data.redaction_id, data.predefined_redaction_id);

      }, error: error => {
        this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
      }
    })
  }

  private updateUI(clone: boolean) {
    if (clone) {
      this.clientpackage.name = "";
    }
    this.selectedRedactionId = this.clientpackage.predefined_redaction_id ?? this.clientpackage.redaction_id;
  }

  onAircraftMoveToTarget(event) {
    this.moveAircraftsToTarget(event);
  }

  onAircraftMoveAllToTarget(event) {
    this.moveAircraftsToTarget(event);
  }

  onAircraftMoveToSource(event) {
    this.moveAircraftsToSource(event);
  }

  onAircraftMoveAllToSource(event) {
    this.moveAircraftsToSource(event);
  }

  private moveAircraftsToTarget(event: any): void {
    event.items.forEach((ele) => {
      if (!this.selectedAircraftGroupIDs.find(a => a.aircraft_registration == ele.aircraft_registration)) {
        this.selectedAircraftGroupIDs.push({
          aircraftgroup_id: ele.aircraftgroup_id,
          aircraft_registration: ele.aircraft_registration,
          aircraft_id: ele.aircraft_id
        });
      }
    });

    this.selectedAircraftGroupIDs = [...new Set(this.selectedAircraftGroupIDs)] // remove the duplicate entries

    this.isAircraftButtonDisabled = false;
    this.isButtonDisabled = false;
    this.pickListChange = true;
    this.ResetSelectedGroup(true);
    this.availableAircraft = this.availableAircraft.sort((a, b)=>a.aircraft_registration.localeCompare(b.aircraft_registration));
    this.selectedAircraft = this.selectedAircraft.sort((a, b)=>a.aircraft_registration.localeCompare(b.aircraft_registration));
  }

  private moveAircraftsToSource(event: any): void {
    event.items.forEach((ele) => {
      const index = this.selectedAircraftGroupIDs.findIndex(a => a.aircraft_registration == ele.aircraft_registration);
      if (index > -1) {
        this.selectedAircraftGroupIDs.splice(index, 1);
      }
    });

    this.selectedAircraftGroupIDs = [...new Set(this.selectedAircraftGroupIDs)].sort(); // remove the duplicate entries

    this.isAircraftButtonDisabled = false;
    this.isButtonDisabled = false;
    this.pickListChange = true;
    this.ResetSelectedGroup(false);
  }

    onChangeAircraftGroup(selectedAircraftGroup: any) {
    this.selectedAircraftGroup = selectedAircraftGroup.value;
    this.getAircraftValuesBinded(selectedAircraftGroup.value, true)
  }

  onChangeTargetAircraftGroup(selectedAircraftGroup: any) {
    this.selectedTargetAircraftGroup = selectedAircraftGroup.value;
    this.getAircraftValuesBinded(selectedAircraftGroup.value, false)
  }

  ResetSelectedGroup(isTarget: boolean) {
    const uniqueSelectedGroupIDs = Array.from(new Set(this.selectedAircraftGroupIDs.map(a => a.aircraftgroup_id)));
    const uniqueSourceGroupIDs = Array.from(new Set(this.availableAircraft.map(a => a.aircraftgroup_id)));

    if (isTarget) {
      this.selectedTargetAircraftGroup = uniqueSelectedGroupIDs.length > 1 ? null : this.selectedAircraftGroup;
      this.getAircraftValuesBinded(this.selectedTargetAircraftGroup, false);
    } else {
      this.selectedAircraftGroup = uniqueSourceGroupIDs.length > 1 ? null : this.selectedTargetAircraftGroup;
      this.getAircraftValuesBinded(this.selectedAircraftGroup, true);
    }
  }

  getAircraftValuesBinded(selectedValue: number, isSource: boolean) {
    this.loadingCount++;
    this.commonService.listAircraftGroupIdFilters(selectedValue == null ? 0 : selectedValue).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: data => {
          if (isSource) {
            this.availableAircraft = [];
            data.forEach(a => {
              if (a.aircraft_registration != null) {
                if (!this.selectedAircraftGroupIDs.find(ele => ele.aircraft_registration == a.aircraft_registration) && !this.availableAircraft.find(ele => ele.aircraft_registration == a.aircraft_registration)) {
                  this.availableAircraft.push({
                    aircraft_registration: a.aircraft_registration,
                    aircraftgroup_id: selectedValue,
                    aircraft_id: a.aircraft_id
                  })
                }
              }
            })
          } else {
            if (selectedValue == null) {
              this.targetAircraft = this.selectedAircraftGroupIDs;
            } else {
              this.targetAircraft = this.selectedAircraftGroupIDs.filter(a => data.find(i => i.aircraft_registration == a.aircraft_registration));
            }
            this.targetAircraft = this.targetAircraft.sort((a, b)=>a.aircraft_registration.localeCompare(b.aircraft_registration));
          }
        },
        error: err => {
          this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () =>
          this.loadingCount--
      });
  }

  onMoveToTarget(event: any): void {
    this.moveEndpointsToTarget(event);
  }

  onMoveAllToSource(event: any): void {
    this.moveEndpointsToSource(event);
  }

  onMoveAllToTarget(event: any): void {
    this.moveEndpointsToTarget(event);
  }

  onMoveToSource(event: any): void {
    this.moveEndpointsToSource(event);
  }

  private moveEndpointsToTarget(event: any): void {
    event.items.forEach((ele: any) => {
      const {endpoint_id} = ele;
      if (!this.selectedEndPointsIDs.includes(endpoint_id)) {
        this.selectedEndPointsIDs.push(endpoint_id);
      }
    });
    this.selectedEndPointsIDs = [...new Set(this.selectedEndPointsIDs)] // remove the duplicate entries
    this.selectedEndPoints = this.selectedEndPoints.sort((a, b)=>a.endpoint_name.localeCompare(b.endpoint_name));
    this.isButtonDisabled = false;
  }

  private moveEndpointsToSource(event: any): void {
    const idsToRemove = new Set(event.items.map((ele: any) => ele.endpoint_id));
    this.selectedEndPointsIDs = this.selectedEndPointsIDs.filter(id => !idsToRemove.has(id));
    this.selectedEndPointsIDs = [...new Set(this.selectedEndPointsIDs)] // remove the duplicate entries
    this.availableEndPoints = this.availableEndPoints.sort((a, b)=>a.endpoint_name.localeCompare(b.endpoint_name));
    this.isButtonDisabled = false;
  }


  onRedactionType(e: any) {
    const selectedRedaction = this.redactionType.find(a => a.redactiontypeId == e.value);
    if (selectedRedaction.isPredefined == true) {
      this.clientpackage.predefined_redaction_id = selectedRedaction.redaction_id;
      this.clientpackage.redaction_id = undefined;
    } else {
      this.clientpackage.redaction_id = selectedRedaction.redaction_id;
      this.clientpackage.predefined_redaction_id = undefined;
    }
  }

  onZipSelection(e: any) {
    if (e.value == 1) {
      this.clientpackage.zip_password = "";
    }
  }

  onPredefinedType(e: any) {
    this.clientpackage.predefined_redaction_id = e.value;
  }

  onConversionType(e: any) {
    if (e.value == this.noConversionId) {
      this.isRedaction = false;
      this.clientpackage.predefined_redaction_id = undefined;
      this.clientpackage.redaction_id = undefined;
      this.selectedRedactionId = undefined;
    } else {
      this.isRedaction = true;
      this.getPredindedRedaction(e.value);
    }
  }

  onCancel() {
    this.ngZone.run(() => {
      this.router.navigate([Components.ClientPackages.path]);
    });
  }

  onReset() {
    this.pickListChange = false;
    this.selectedAircraftIDs = [];
    this.selectedEndPointsIDs = [];
    this.selectedEndPoints = [];
    this.selectedAircraft = [];
    this.selectedAircraftGroupIDs = [];
    this.selectedAircraftGroup = null;
    this.selectedTargetAircraftGroup = null;
    this.ngOnInit();
    this.isButtonDisabled = true;
  }

  submitForm(clientPackage: ClientPackages) {
    this.clientpackage = clientPackage;
    this.onSubmit();
  }

  onSubmit() {
    this.loadingCount++;
    this.selectedAircraftIDs = [];
    this.selectedAircraftGroupIDs.forEach(a => {
      this.selectedAircraftIDs.push(a.aircraft_id)
    });
    this.responseModelClientPackages = {
      package_id: this.clientpackage.package_id,
      conversiontype_id: this.clientpackage.conversiontype_id,
      file_name: this.clientpackage.file_name == null ? this.defaultfileName : this.clientpackage.file_name,
      description: this.clientpackage.description,
      name: this.clientpackage.name,
      redaction_id: this.clientpackage.redaction_id,
      predefined_redaction_id: this.clientpackage.predefined_redaction_id,
      ziptype_id: this.clientpackage.ziptype_id,
      zip_password: this.clientpackage.zip_password == undefined ? "" : this.clientpackage.zip_password,
      aircraft_id: this.selectedAircraftIDs,
      endpoint_id: this.selectedEndPointsIDs,
      organization_id: 0,
      primary: this.clientpackage.primary == undefined ? false : this.clientpackage.primary,
      secondary: this.clientpackage.secondary == undefined ? false : this.clientpackage.secondary,
      enhanced: false
    }
    if (this.isNew) {
      this.clientPackagesService.addClientPackage(this.responseModelClientPackages).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
        next: result => {
          this.clientpackage.package_id = result;
          // Update query param
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              packageId: this.clientpackage.package_id,
              name: this.clientpackage.name
            },
            queryParamsHandling: 'merge'
          }).then();

          this.updateBreadcrumb(Components.AddClientPackages.label + ' (' + this.clientpackage.name + ')');
          this.pageTitle = Components.AddClientPackages.label;
          this.pickListChange = false;
        },
        error: err => {
          this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
          this.loadingCount--;
          this.isButtonDisabled = true;
          this.saveButtonDisabled = true;
        },
        complete: () => {
          this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.clientpackage.name}`);
          this.isNew = false;
          this.loadingCount--;
          this.isButtonDisabled = true;
          this.saveButtonDisabled = true;
          this.ngOnInit;
        }
      });
    } else {
      this.clientPackagesService.updateClientPackage(this.responseModelClientPackages).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
        next: () => {
          this.updateBreadcrumb(Components.AddClientPackages.label + ' (' + this.clientpackage.name + ')');
          this.pickListChange = false;
        },
        error: err => {
          this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
          this.loadingCount--;
          this.isButtonDisabled = true;
          this.saveButtonDisabled = true;
        },
        complete: () => {
          this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.clientpackage.name}`);
          this.loadingCount--;
          this.isButtonDisabled = true;
          this.ngOnInit;
        }
      });
    }
  }
}