<dynamic-form [formFields]="myForm" [isEditMode]="false" (savedClick)="OnSubmit($event)" [isDetailsMode]="false"
    [formData]="formdata" [pageTitle]="title" (onFormReset)="formReset()" (onFormCancel)="formCancel()"
    [isSaveButtonDisabled]="isSaveButtonDisabled()" (onChange)="handleChangeEvent($event)">

    <div class="tdy-required bottomspace" *ngIf="(colsdata.length === 0 && userCanManage)">
        At least one item required in Recording tab.
    </div>
    <div class="tdy-required bottomspace"
        *ngIf="(SelectedEuType?.value === 1 &&  euColsdata.length === 0 && userCanManage)">
        At least one of the 5 coefficient is required (the one with a corresponding exp) in EU Conversion tab.
    </div>
    <div class="tdy-required bottomspace" *ngIf="(SelectedEuType?.value === 17 && euItems.length < 2 && userCanManage)">
        At least 2 pairs of Raw Value and EU Value is required in EU Conversion tab.
    </div>
    <div class="tdy-required bottomspace"
        *ngIf="(selectedValue?.value === 3 && disColsdata.length < 2 && userCanManage)">
        At least 2 pairs of Raw Value and Discrete Text is required in Discrete Text tab.
    </div>
    <div class="tdy-required bottomspace" *ngIf="newRow === true && userCanManage">
        At least one pair of value is required in the newly added row in EU Conversion tab.
    </div>

    <div class="card">


        <p-accordion [multiple]="true">
            <p-accordionTab header="General">
                <div class="formgrid grid">
                    <div class=" lg:col-4">
                        <span class="p-float-label">
                            <input type="text" id="PRM_UNIT" name="PRM_UNIT" pInputText #PRM_UNIT="ngModel"
                                [style]="{'width':'100%'}" [(ngModel)]="tabData.PRM_UNIT" [disabled]="!userCanManage" />
                            <label for="PRM_UNIT">Unit</label>
                        </span>
                    </div>
                    <div class=" lg:col-4">
                        <span class="p-float-label">
                            <input type="number" id="PRM_RATE" name="PRM_RATE" pInputText #PRM_RATE="ngModel"
                                [(ngModel)]="tabData.PRM_RATE" [style]="{'width': '100%'}" readonly
                                [disabled]="!userCanManage" />
                            <label for="PRM_RATE">Parameter Rate</label>
                        </span>
                    </div>
                    <div class=" lg:col-4">
                        <span class="p-float-label">
                            <input id="PRM_MIN_OP_RANGE" name="PRM_MIN_OP_RANGE" type="number"
                                [(ngModel)]="tabData.PRM_MIN_OP_RANGE" #PRM_MIN_OP_RANGE="ngModel" pInputText
                                [disabled]="!minbool || !userCanManage" [style]="{'width':'100%'}" required
                                [max]="tabData.PRM_MAX_OP_RANGE" />
                            <label for="PRM_MIN_OP_RANGE">Min Operation Range</label> </span>
                        <div class="tdy-required"
                            *ngIf="(PRM_MIN_OP_RANGE.dirty  || PRM_MIN_OP_RANGE.touched) && PRM_MIN_OP_RANGE.errors?.['required']">
                            Min Operation Range is required.
                        </div>
                        <div>
                            <span *ngIf="(tabData.PRM_MAX_OP_RANGE > 0)">
                                <span *ngIf="!(tabData.PRM_MAX_OP_RANGE >= tabData.PRM_MIN_OP_RANGE) "
                                    class="tdy-required">Min Operation Range should not be greater than Max
                                    Operation Range.
                                </span>
                            </span>
                        </div>

                    </div>
                    <div class=" lg:col-4">
                        <span class="p-float-label">
                            <input id="PRM_MAX_OP_RANGE" name="PRM_MAX_OP_RANGE" [(ngModel)]="tabData.PRM_MAX_OP_RANGE"
                                #PRM_MAX_OP_RANGE="ngModel" type="number" pInputText [style]="{'width':'100%'}" required
                                [min]="tabData.PRM_MIN_OP_RANGE" [disabled]="!userCanManage" />
                            <label for="PRM_MAX_OP_RANGE">Max Operation Range</label>
                        </span>
                        <div class="tdy-required"
                            *ngIf="(PRM_MAX_OP_RANGE.dirty  || PRM_MAX_OP_RANGE.touched) && PRM_MAX_OP_RANGE.errors?.['required']">
                            Max Operation Range is required.
                        </div>
                        <div>
                            <div *ngIf="tabData.PRM_MAX_OP_RANGE">
                                <div *ngIf="(!(tabData.PRM_MAX_OP_RANGE > tabData.PRM_MIN_OP_RANGE) ) "
                                    class="tdy-required">Max Operation Range should be greater than Min
                                    operational range.
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class=" lg:col-4">
                        <span class="p-float-label">
                            <p-dropdown id="FMT_ID_DISPLAY" name="FMT_ID_DISPLAY" (onChange)="onDisplayChange($event)"
                                [options]="displayFormat" [(ngModel)]="displaySelectedValue" #FMT_ID_DISPLAY="ngModel"
                                [disabled]="disableDisplayDropdown || !userCanManage" [required]="true"
                                [style]="{'width':'100%'}" optionLabel="type" [autoDisplayFirst]="false"></p-dropdown>
                            <label for="FMT_ID_DISPLAY">Display Format</label>
                        </span>
                        <div class="tdy-required"
                            *ngIf="(PRM_MAX_OP_RANGE.dirty  || PRM_MAX_OP_RANGE.touched) && PRM_MAX_OP_RANGE.errors?.['required']">
                            Max Operation Range is required.
                        </div>
                    </div>

                    <div class=" lg:col-3">
                        <span class="p-float-label">
                            <input id="PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT" name="PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT"
                                [(ngModel)]="tabData.PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT"
                                #PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT="ngModel" pInputText [style]="{'width':'100%'}"
                                required [disabled]="!userCanManage" />
                            <label for="PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT">Display Formatting</label>
                        </span>
                        <div class="tdy-required"
                            *ngIf="(PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT.dirty  || PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT.touched) && PRM_PLOTLIST_DEFAULT_DISPLAY_FORMAT.errors?.['required']">
                            Dsiplay Formatting is required.
                        </div>
                    </div>
                </div>
            </p-accordionTab>

            <p-accordionTab header="Recording">
                <div class="formgrid grid">

                    <div class=" lg:col-4">
                        <span class="p-float-label">
                            <input type="number" id="COMP_NUM" name="COMP_NUM" pInputText #COMP_NUM="ngModel"
                                [(ngModel)]="colsdata.length" [pKeyFilter]="'int'" [style]="{'width': '100%'}" readonly
                                [disabled]="!userCanManage" />
                            <label for="COMP_NUM">Number of Components</label>
                        </span>
                    </div>

                    <div class=" lg:col-3">
                        <span class="p-float-label">
                            <p-dropdown id="FMT_ID" name="FMT_ID" (onChange)="onChange($event)"
                                [options]="storageFormat" [(ngModel)]="selectedValue" #FMT_ID="ngModel"
                                [required]="true" [style]="{'width':'100%'}" optionLabel="type" [showClear]="false"
                                [autoDisplayFirst]="false" [disabled]="!userCanManage"></p-dropdown>
                            <label for="FMT_ID">Storage Format</label>
                        </span>
                        <div class="tdy-required"
                            *ngIf="(FMT_ID.dirty  || FMT_ID.touched ) && FMT_ID.errors?.['required']">
                            Storage Format is required.
                        </div>

                    </div>

                    <div class=" lg:col-3">
                        <span class="p-float-label">
                            <input type="text" id="PRA_CONV_CONF" name="PRA_CONV_CONF" pInputText
                                #PRA_CONV_CONF="ngModel" [(ngModel)]="formdata.PRA_CONV_CONF"
                                [style]="{'width': '100%'}" [disabled]="configbool && (!userCanManage ||userCanManage)"
                                [maxlength]="selectedValue?.value === 4?'1':''" pattern=".*\S.*"
                                (input)="findBCD(formdata.PRA_CONV_CONF)"
                                [required]="selectedValue?.value === 4||selectedValue?.value === 2" />
                            <label for="PRA_CONV_CONF">Conversion Format</label> </span>

                        <div class="tdy-required" *ngIf="PRA_CONV_CONF.hasError('required')">
                            Conversion Format is required.
                        </div>
                        <div class="tdy-required" *ngIf="PRA_CONV_CONF.hasError('pattern')">
                            Conversion Format cannot be blank.
                        </div>
                        <div class="tdy-required" *ngIf="digitErr && PRA_CONV_CONF">
                            If Conversion Format, highest possible character to enter is 4 (e.g. 442 is ok).
                        </div>
                        <div class="tdy-required" *ngIf="bcdBool && PRA_CONV_CONF">
                            Sum of value should be equal to total Sample Bit Length.
                        </div>
                    </div>

                    <div class="col-3 p-2">
                        <span>
                            <p-checkbox [(ngModel)]="Signedvalue" ngDefaultControl (onChange)="onSignedChange($event)"
                                name="Signedvalue" inputId="Signedvalue" [binary]="true"
                                [disabled]="!userCanManage"></p-checkbox>
                            <label class="p-2" for="Signedvalue">Signed</label>
                        </span>
                    </div>

                </div>
                <div class="">
                    <div class="flex justify-content-end mb-3">
                        <button id="lcpButton" name="lcpButton" type="button" label="Add Sample" pButton pRipple
                            icon="pi pi-plus" (click)="addLCPparameters(colsdata)" *ngIf="userCanManage"></button>
                    </div>
                </div>
                <p-table #recordings (onEditInit)="onRowEdit($event)" [value]="colsdata" class="table_locations"
                    [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"'>

                    <ng-template pTemplate="header" dataKey="rectab" editMode="row">
                        <tr>
                            <th pSortableColumn={{rec.field}} *ngFor="let rec of cols">
                                {{rec.header}}
                            </th>
                            <th *ngIf="userCanManage">&nbsp;</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-recordingData let-recdata let-ri="rowIndex">
                        <tr>
                            <td [pEditableColumn]="recdata.LCP_SAMPLE_NUMBER" class="text-center">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input id="LCP_SAMPLE_NUMBER" type="number" min="1"
                                            [(ngModel)]="recdata.LCP_SAMPLE_NUMBER" [pKeyFilter]="'int'" required
                                            name="LCP_SAMPLE_NUMBER" pInputText (keypress)="onKeySearch($event)"
                                            #LCP_SAMPLE_NUMBER="ngModel"
                                            (input)="editSampleNum(recdata.LCP_SAMPLE_NUMBER,ri)"
                                            [disabled]="!userCanManage" />
                                        <div class="tdy-required"
                                            *ngIf="( LCP_SAMPLE_NUMBER.dirty  || LCP_SAMPLE_NUMBER.touched ) && LCP_SAMPLE_NUMBER.hasError('required')">
                                            Sample Number is required.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="( LCP_SAMPLE_NUMBER.dirty  || LCP_SAMPLE_NUMBER.touched ) && LCP_SAMPLE_NUMBER.hasError('min')">
                                            Sample Number should be greater than 0.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf=" recdata.LCP_SAMPLE_NUMBER != (1 || 0) && ri === 0 && recdata.LCP_SAMPLE_NUMBER">
                                            Sample Number should be start from 1.

                                        </div>
                                        <div *ngFor="let index of sampleIndex">
                                            <div class="tdy-required"
                                                *ngIf="ri == index  && recdata.LCP_SAMPLE_NUMBER != 0 && ri != 0">
                                                Sample Number should be in sequential order.
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="edit_input">{{recdata.LCP_SAMPLE_NUMBER}}


                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>
                                        <div *ngFor="let index of sampleIndex">
                                            <div class="tdy-required" *ngIf="ri == index">
                                                Sample Number should be in sequential order.
                                            </div>
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf=" recdata.LCP_SAMPLE_NUMBER != (1 || 0) && ri === 0">
                                            Sample Number should be start from 1.
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-center">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText id="LCP_WORD_NB"
                                            [(ngModel)]="recdata.LCP_WORD_NB" [pKeyFilter]="'int'" name="LCP_WORD_NB"
                                            [min]="1" #LCP_WORD_NB="ngModel" [max]="wordspersecond" required
                                            [disabled]="!userCanManage" />
                                        <div class="tdy-required"
                                            *ngIf="( LCP_WORD_NB.dirty  || LCP_WORD_NB.touched ) && LCP_WORD_NB.hasError('required')">
                                            Word Number is required.
                                        </div>
                                        <div class="tdy-required" *ngIf="LCP_WORD_NB.hasError('min')">
                                            Word Number should be greater than 0.
                                        </div>
                                        <div class="tdy-required" *ngIf="LCP_WORD_NB.hasError('max')">
                                            Word Number should not be greater than Words Per Second({{wordspersecond}}).
                                        </div>
                                        <div>
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="edit_input">
                                            {{recdata.LCP_WORD_NB}}

                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>

                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-center">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown id="recdata.LCP_SUBFRAME" name="recdata.LCP_SUBFRAME"
                                            (onChange)="onSubFrameChange($event)" [options]="SubFrameArray"
                                            [(ngModel)]="recdata.LCP_SUBFRAME" #recdata.LCP_SUBFRAME="ngModel"
                                            [showClear]="false" appendTo="body" [required]="true"
                                            [autoDisplayFirst]="false" [disabled]="!userCanManage">
                                            <label for="LCP_TYPE">LCP TYPE</label>
                                        </p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">

                                        <div class="edit_input">
                                            {{recdata.LCP_SUBFRAME}}

                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>


                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-center">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" [(ngModel)]="recdata.LCP_BIT_SOURCE_LSB"
                                            [pKeyFilter]="'int'" id="recdata.LCP_BIT_SOURCE_LSB"
                                            name="recdata.LCP_BIT_SOURCE_LSB" required #LCP_BIT_SOURCE_LSB="ngModel"
                                            [min]="1" [max]="12" [disabled]="!userCanManage" />
                                        <div class="tdy-required"
                                            *ngIf="( LCP_BIT_SOURCE_LSB.dirty  || LCP_BIT_SOURCE_LSB.touched ) && LCP_BIT_SOURCE_LSB.hasError('required')">
                                            Starting Bit is required.
                                        </div>
                                        <div class="tdy-required" *ngIf="LCP_BIT_SOURCE_LSB.hasError('min')">
                                            Starting Bit should be greater than 0.
                                        </div>
                                        <div class="tdy-required" *ngIf="LCP_BIT_SOURCE_LSB.hasError('max')">
                                            Starting Bit should not be greater than 12.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="(recdata.LCP_BIT_SOURCE_LSB + recdata.LCP_BIT_LENGTH > 13) && recdata.LCP_BIT_SOURCE_LSB <= 12">
                                            Sum of Starting Bit and Bit Length should not be greater than 13.
                                        </div>

                                    </ng-template>
                                    <ng-template pTemplate="output">

                                        <div class="edit_input">
                                            {{recdata.LCP_BIT_SOURCE_LSB}}

                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>

                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-center">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText [(ngModel)]="recdata.LCP_BIT_LENGTH"
                                            (input)="calculateBitLength()" [pKeyFilter]="'int'" id="LCP_BIT_LENGTH"
                                            name="LCP_BIT_LENGTH" (focusout)="getBitValue($event)"
                                            #LCP_BIT_LENGTH="ngModel" [min]="1" [max]="12" required
                                            [disabled]="!userCanManage" />
                                        <div class="tdy-required"
                                            *ngIf="( LCP_BIT_LENGTH.dirty  || LCP_BIT_LENGTH.touched ) && LCP_BIT_LENGTH.hasError('required')">
                                            Bit Length is required.
                                        </div>
                                        <div class="tdy-required" *ngIf="LCP_BIT_LENGTH.hasError('min')">
                                            Bit Length should be greater than 0.
                                        </div>
                                        <div class="tdy-required" *ngIf="LCP_BIT_LENGTH.hasError('max')">
                                            Bit Length should not be greater than 12.
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="(recdata.LCP_BIT_SOURCE_LSB + recdata.LCP_BIT_LENGTH > 13 && recdata.LCP_BIT_LENGTH <= 12)">
                                            Sum of Starting Bit and Bit Length should not be greater than 13.
                                        </div>
                                        <div *ngFor="let index of bitLengthArray">
                                            <div class="tdy-required" *ngIf="ri == index && recdata.LCP_BIT_LENGTH">
                                                Bit Length should be same per Sample Number.
                                            </div>
                                        </div>
                                        <div class="tdy-required" *ngIf="(selectedValue?.value == 4 &&recdata.LCP_BIT_LENGTH !==null&&
                                    !(recdata.LCP_BIT_LENGTH==7||recdata.LCP_BIT_LENGTH==8)&&
                                    !(recdata.LCP_BIT_LENGTH==0)&&
                                    !(recdata.LCP_BIT_LENGTH>12))">
                                            Bit Length should be 7 or 8 for ASCII & ISO#5.
                                        </div>

                                    </ng-template>
                                    <ng-template pTemplate="output">
                                         <div class="edit_input">
    
 {{recdata.LCP_BIT_LENGTH}} 
                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>
                                        <div class="tdy-required"
                                            *ngIf="(!(recdata.LCP_BIT_LENGTH == 7||recdata.LCP_BIT_LENGTH == 8)&&(selectedValue?.value == 4)&&!(recdata.LCP_BIT_LENGTH==null))">
                                            Bit Length should be 7 or 8 for ASCII & ISO#5.
                                        </div>
                                        <div *ngFor="let index of bitLengthArray">
                                            <div class="tdy-required" *ngIf="ri == index && recdata.LCP_BIT_LENGTH">
                                                Bit Length should be same per Sample Number.
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-center">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" [(ngModel)]="frameLength" [pKeyFilter]="'int'"
                                            #recdata.PRM_RATE="ngModel" id="recdata.PRM_RATE" name="recdata.PRM_RATE"
                                            required readonly [disabled]="!userCanManage" />
                                        <div class="tdy-required"
                                            *ngIf="( PRM_RATE.dirty  || PRM_RATE.touched ) && PRM_RATE.hasError('required')">
                                            Rate is required.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                         <div class="edit_input">
     {{frameLength}} 

                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-center">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <p-dropdown id="LCP_TYPE" name="LCP_TYPE" (onChange)="onComponentChange($event)"
                                            [options]="ComponentFormat" [(ngModel)]="recdata.LCP_TYPE"
                                            #LCP_TYPE="ngModel" tooltip="Component Type" appendTo="body"
                                            [required]="true" [showClear]="false" [autoDisplayFirst]="false"
                                            [disabled]="!userCanManage"></p-dropdown>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                    <div class="edit_input">
    
      {{recdata.LCP_TYPE}} 
                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-center">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" [(ngModel)]="recdata.LCP_MODULO"
                                            [pKeyFilter]="'int'" id="LCP_MODULO" (keypress)="onKeySearch($event)"
                                            [disabled]="(!(recdata.LCP_TYPE === superFrame)? true:false ) && (!userCanManage ||userCanManage)"
                                            name="LCP_MODULO" [min]="1" #LCP_MODULO="ngModel" [max]="16" required />
                                        <div class="tdy-required"
                                            *ngIf="( LCP_MODULO.dirty  || LCP_MODULO.touched ) && LCP_MODULO.hasError('required')">
                                            Frame Number of Superframe is required.
                                        </div>
                                        <div class="tdy-required" *ngIf="LCP_MODULO.hasError('min')">
                                            Frame Number of Superframe should not be less than 0.
                                        </div>
                                        <div class="tdy-required" *ngIf="LCP_MODULO.hasError('max')">
                                            Frame Number of Superframe should not be greater than 15.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                       <div class="edit_input">
    
  {{recdata.LCP_MODULO}}  
                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>

                            <td> <button id="LCP_del_Button" name="LCP_del_Button" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash" pTooltip="Delete" *ngIf="userCanManage"
                                    (click)="openDeleteDialog(recdata,ri)"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>

            <p-accordionTab header="EU Conversion">
                <div class="formgrid grid">
                    <span class="p-float-label">
                        <p-dropdown id="EUT_ID" [options]="euType" (onChange)="onEUTypeChange($event)" name="EUT_ID"
                            [(ngModel)]="SelectedEuType" #EUT_ID="ngModel" [required]="true" optionLabel="type"
                            [showClear]="false" [style]="{'width':'100%'}" [autoDisplayFirst]="false"
                            [disabled]="!userCanManage"></p-dropdown>
                        <label for="EUT_ID">EU Type</label></span>
                    <div class="tdy-required" *ngIf="(EUT_ID.dirty  || EUT_ID.touched ) && EUT_ID.errors?.['required']">
                        EU Type is required.
                    </div>
                </div>
                <div *ngIf="SelectedEuType?.value === 1">
                    <div class="flex justify-content-end  mb-3">
                        <button id="EUC_Button" name="EUC_Button" type="button" label="Add Segment"
                            (click)="addEUparameters()" *ngIf="userCanManage" pButton pRipple
                            icon="pi pi-plus"></button>
                    </div>
                    <p-table [value]="euColsdata" #euConversion class="recoder_eu_table"
                        [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"'>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn={{rec.field}} *ngFor="let rec of euCols">
                                    {{rec.header}}
                                </th>
                                <th *ngIf="userCanManage">&nbsp;</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-eudata let-editing="editing" let-eui="rowIndex">
                            <tr>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_0,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_0" id="eudata.EUC_COEFF_0"
                                                name="eudata.EUC_COEFF_0" #EUC_COEFF_0="ngModel" required
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_0.dirty  || EUC_COEFF_0.touched) && EUC_COEFF_0.hasError('required')">
                                                Coeff 0 is required.
                                            </div>
                                            <span class="tdy-required" *ngIf="(eudata.EUC_COEFF_0 === 0)">
                                                Coeff 0 should not be equal to 0.
                                            </span>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                          <div class="edit_input">
        {{eudata.EUC_COEFF_0}} 

                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_EXP_0 && eudata.EUC_COEFF_0 === 0 && isValueNull(eudata.EUC_COEFF_0)">
                                                Coeff 0 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_EXP_0,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_EXP_0"
                                                id="eudata.EUC_COEFF_EXP_0" name="eudata.EUC_COEFF_EXP_0"
                                                #EUC_COEFF_EXP_0="ngModel" required [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_EXP_0.dirty  || EUC_COEFF_EXP_0.touched || (!eudata.EUC_COEFF_EXP_0 && eudata.EUC_COEFF_0)) && EUC_COEFF_EXP_0.hasError('required')">
                                                Exp 0 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_EXP_0 === 0">
                                                Exp 0 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                           <div class="edit_input">
       {{eudata.EUC_COEFF_EXP_0}} 

                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_0 && eudata.EUC_COEFF_EXP_0 === 0 && isValueNull(eudata.EUC_COEFF_EXP_0)">
                                                Exp 0 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input (input)="enteredValue(eudata.EUC_COEFF_1,eui)" type="number"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_1" id="eudata.EUC_COEFF_1"
                                                name="eudata.EUC_COEFF_1" #EUC_COEFF_1="ngModel"
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_1.dirty  || EUC_COEFF_1.touched || (!eudata.EUC_COEFF_1 && eudata.EUC_COEFF_EXP_1)) && EUC_COEFF_1.hasError('required')">
                                                Coeff 1 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_1 === 0">
                                                Coeff 1 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                          <div class="edit_input">
        {{eudata.EUC_COEFF_1}} 

                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_EXP_1 && eudata.EUC_COEFF_1 === 0 && isValueNull(eudata.EUC_COEFF_1) ">
                                                Coeff 1 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_EXP_1,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_EXP_1"
                                                id="eudata.EUC_COEFF_EXP_1" name="eudata.EUC_COEFF_EXP_1"
                                                #EUC_COEFF_EXP_1="ngModel" [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_EXP_1.dirty  || EUC_COEFF_EXP_1.touched || (!eudata.EUC_COEFF_EXP_1 && eudata.EUC_COEFF_1)) && EUC_COEFF_EXP_1.hasError('required')">
                                                Exp 1 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_EXP_1 === 0">
                                                Exp 1 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                           <div class="edit_input">
    
  {{eudata.EUC_COEFF_EXP_1}}  
                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_1 && eudata.EUC_COEFF_EXP_1 === 0 && isValueNull(eudata.EUC_COEFF_EXP_1) ">
                                                Exp 1 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_2,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_2" id="eudata.EUC_COEFF_2"
                                                name="eudata.EUC_COEFF_2" #EUC_COEFF_2="ngModel"
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_2.dirty  || EUC_COEFF_2.touched || (!eudata.EUC_COEFF_2 && eudata.EUC_COEFF_EXP_2)) && EUC_COEFF_2.hasError('required')">
                                                Coeff 2 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_2 === 0">
                                                Coeff 2 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                           <div class="edit_input">
       {{eudata.EUC_COEFF_2}} 

                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_EXP_2 && eudata.EUC_COEFF_2 === 0 && isValueNull(eudata.EUC_COEFF_2) ">
                                                Coeff 2 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_EXP_2,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_EXP_2"
                                                id="eudata.EUC_COEFF_EXP_2" name="eudata.EUC_COEFF_EXP_2"
                                                #EUC_COEFF_EXP_2="ngModel" [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_EXP_2.dirty  || EUC_COEFF_EXP_2.touched || (!eudata.EUC_COEFF_EXP_2 && eudata.EUC_COEFF_2)) && EUC_COEFF_EXP_2.hasError('required')">
                                                Exp 2 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_EXP_2 === 0">
                                                Exp 2 should not be equal to 0.
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_2 && eudata.EUC_COEFF_EXP_2 === 0 ">
                                                Exp 2 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                          <div class="edit_input">
        {{eudata.EUC_COEFF_EXP_2}} 

                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_2 && eudata.EUC_COEFF_EXP_2 === 0 && isValueNull(eudata.EUC_COEFF_EXP_2) ">
                                                Exp 2 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_3,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_3" id="eudata.EUC_COEFF_3"
                                                name="eudata.EUC_COEFF_3" #EUC_COEFF_3="ngModel"
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_3.dirty  || EUC_COEFF_3.touched || (!eudata.EUC_COEFF_3 && eudata.EUC_COEFF_EXP_3)) && EUC_COEFF_3.hasError('required')">
                                                Coeff 3 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_3 === 0">
                                                Coeff 3 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                          <div class="edit_input">
    
    {{eudata.EUC_COEFF_3}} 
                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_EXP_3 && eudata.EUC_COEFF_3 === 0 && isValueNull(eudata.EUC_COEFF_3)">
                                                Coeff 3 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_EXP_3,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_EXP_3"
                                                id="eudata.EUC_COEFF_EXP_3" name="eudata.EUC_COEFF_EXP_3"
                                                #EUC_COEFF_EXP_3="ngModel" [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_EXP_3.dirty  || EUC_COEFF_EXP_3.touched || (!eudata.EUC_COEFF_EXP_3 && eudata.EUC_COEFF_3)) && EUC_COEFF_EXP_3.hasError('required')">
                                                Exp 3 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_EXP_3 === 0">
                                                Exp 3 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div class="edit_input">
    
 {{eudata.EUC_COEFF_EXP_3}}  
                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_3 && eudata.EUC_COEFF_EXP_3 === 0 && isValueNull(eudata.EUC_COEFF_EXP_3) ">
                                                Exp 3 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_4,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_4" id="eudata.EUC_COEFF_4"
                                                name="eudata.EUC_COEFF_4" #EUC_COEFF_4="ngModel"
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_4.dirty  || EUC_COEFF_4.touched || (!eudata.EUC_COEFF_4 && eudata.EUC_COEFF_EXP_4)) && EUC_COEFF_4.hasError('required')">
                                                Coeff 4 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_4 === 0">
                                                Coeff 4 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                      <div class="edit_input">
    
        {{eudata.EUC_COEFF_4}} 
                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_EXP_4 && eudata.EUC_COEFF_4 === 0 && isValueNull(eudata.EUC_COEFF_EXP_4) ">
                                                Coeff 4 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_EXP_4,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_EXP_4"
                                                id="eudata.EUC_COEFF_EXP_4" name="eudata.EUC_COEFF_EXP_4"
                                                #EUC_COEFF_EXP_4="ngModel" [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_EXP_4.dirty  || EUC_COEFF_EXP_4.touched || (!eudata.EUC_COEFF_EXP_4 && eudata.EUC_COEFF_4)) && EUC_COEFF_EXP_4.hasError('required')">
                                                Exp 4 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_EXP_4 === 0">
                                                Exp 4 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                              <div class="edit_input">
    {{eudata.EUC_COEFF_EXP_4}} 

                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_4 && eudata.EUC_COEFF_EXP_4 === 0 && isValueNull(eudata.EUC_COEFF_EXP_4)">
                                                Exp 4 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" (input)="enteredValue(eudata.EUC_COEFF_5,eui)"
                                                pInputText [(ngModel)]="eudata.EUC_COEFF_5" id="eudata.EUC_COEFF_5"
                                                name="eudata.EUC_COEFF_5" #EUC_COEFF_5="ngModel"
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_5.dirty  || EUC_COEFF_5.touched || (!eudata.EUC_COEFF_5 && eudata.EUC_COEFF_EXP_5)) && EUC_COEFF_5.hasError('required')">
                                                Coeff 5 is required.
                                            </div>
                                            <div class="tdy-required " *ngIf="eudata.EUC_COEFF_5 === 0">
                                                Coeff 5 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div class="edit_input">
      {{eudata.EUC_COEFF_5}} 

                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_EXP_5 && eudata.EUC_COEFF_5 === 0 &&isValueNull(eudata.EUC_COEFF_5)">
                                                Coeff 5 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText
                                                (input)="enteredValue(eudata.EUC_COEFF_EXP_5,eui)"
                                                [(ngModel)]="eudata.EUC_COEFF_EXP_5" id="eudata.EUC_COEFF_EXP_5"
                                                name="eudata.EUC_COEFF_EXP_5" #EUC_COEFF_EXP_5="ngModel"
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="(EUC_COEFF_EXP_5.dirty  || EUC_COEFF_EXP_5.touched || (!eudata.EUC_COEFF_EXP_5 && eudata.EUC_COEFF_5)) && EUC_COEFF_EXP_5.hasError('required')">
                                                Exp 5 is required.
                                            </div>
                                            <div class="tdy-required" *ngIf="eudata.EUC_COEFF_EXP_5 === 0">
                                                Exp 5 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div class="edit_input">
    
  {{eudata.EUC_COEFF_EXP_5}} 
                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="eudata.EUC_COEFF_5 && eudata.EUC_COEFF_EXP_5 === 0 &&isValueNull(eudata.EUC_COEFF_EXP_5)">
                                                Exp 5 should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td> <button id="EUC_del_Button" name="EUC_del_Button" type="button"
                                        class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                        icon="pi pi-trash" pTooltip="Delete" (click)="openDeleteDialog(eudata,eui)"
                                        *ngIf="userCanManage"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div *ngIf="SelectedEuType?.value === 17">
                    <div class="flex justify-content-end  mb-3">
                        <button id="EU_Button" name="EU_Button" type="button" label="Add EU" (click)="addEUItems()"
                            pButton pRipple *ngIf="userCanManage" icon="pi pi-plus"></button>
                    </div>
                    <p-table [value]="euItems" class="table_all_common"
                        [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"'>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn={{rec.field}} *ngFor="let rec of euColItem">
                                    {{rec.header}}
                                </th>
                                <th *ngIf="userCanManage">&nbsp;</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-euitem let-editing="editing" let-ri="rowIndex">
                            <tr>
                                <td pEditableColumn class="text-left">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="euitem.EUL_RAW_DATA_VALUE"
                                                id="EUL_RAW_DATA_VALUE" name="EUL_RAW_DATA_VALUE"
                                                #EUL_RAW_DATA_VALUE="ngModel" [pKeyFilter]="'int'" required
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="( EUL_RAW_DATA_VALUE.dirty  || EUL_RAW_DATA_VALUE.touched) && EUL_RAW_DATA_VALUE.hasError('required')">
                                                Raw Data Value is required.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                          <div class="edit_input">
    
   {{euitem.EUL_RAW_DATA_VALUE}}  
                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="euitem.EUL_RAW_DATA_VALUE  && isValueNull(euitem.EUL_RAW_DATA_VALUE)">
                                                Raw Data Value should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn class="text-left">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText [(ngModel)]="euitem.EUL_EU_VALUE"
                                                id="EUL_EU_VALUE" name="EUL_EU_VALUE" #EUL_EU_VALUE="ngModel" required
                                                [disabled]="!userCanManage" />
                                            <div class="tdy-required"
                                                *ngIf="( EUL_EU_VALUE.dirty  || EUL_EU_VALUE.touched) && EUL_EU_VALUE.hasError('required')">
                                                EU Value is required.
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div class="edit_input">
      {{euitem.EUL_EU_VALUE}} 

                                                <i class="edit_icon material-symbols-outlined">
                                                    edit
                                                </i>
                                            </div>
                                            <div class="tdy-required"
                                                *ngIf="euitem.EUL_EU_VALUE && isValueNull(euitem.EUL_EU_VALUE)">
                                                EU Value should not be equal to 0.
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="text-align: right !important;"> <button id="EUC_del_Button" name="EUC_del_Button" type="button"
                                        class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                        icon="pi pi-trash" pTooltip="Delete" *ngIf="userCanManage"
                                        (click)="openDeleteDialog(euitem,ri)"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-accordionTab>

            <p-accordionTab header="Discrete Text">
                <div class="tdy-required bottomspace"
                    *ngIf="(selectedValue?.value === 3 && SelectedEuType?.value != 0 && userCanManage)">
                    Set EU Type is None to add values in Discrete Text tab.
                </div>
                <div class="flex justify-content-end mb-3">
                    <button id="TXD_Button" name="TXD_Button" type="button" label="Add Discrete Text" pButton pRipple
                        icon="pi pi-plus"
                        [pTooltip]="(!(SelectedEuType?.value === 0) ? true : false) || (discRowLimit == true) || (bitLengthBool == true) || (descErr==true) || (discTableErr==true) ?'Enter valid data.':'Add'"
                        (click)="addDiscreteParameters(disColsdata)" *ngIf="userCanManage"></button>
                </div>
                <p-table [value]="disColsdata" #discrete class="table_discrete"
                    [selectionMode]='((!userCanView  && userCanManage)|| (!userCanView  && !userCanManage))? "":"single"'>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn={{dis.field}} *ngFor="let dis of disCols">
                                {{dis.header}}
                            </th>
                            <th *ngIf="userCanManage">&nbsp;</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-discData let-editing="editing" let-di="rowIndex">
                        <tr>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="number" pInputText [(ngModel)]="discData.TXD_RAW_DATA_VALUE"
                                            name="TXD_RAW_DATA_VALUE" id="TXD_RAW_DATA_VALUE"
                                            (input)="checkBitLength(discData.TXD_RAW_DATA_VALUE,di)"
                                            #TXD_RAW_DATA_VALUE="ngModel" [min]="0" [max]="discInputValue" required
                                            [pKeyFilter]="'int'" [disabled]="!userCanManage" />
                                        <div class="tdy-required"
                                            *ngIf="( TXD_RAW_DATA_VALUE.dirty  || TXD_RAW_DATA_VALUE.touched) && TXD_RAW_DATA_VALUE.hasError('required')">
                                            Discrete Value is required.
                                        </div>
                                        <div class="tdy-required" *ngIf="TXD_RAW_DATA_VALUE.hasError('max')">
                                            Discrete Value should not be greater than Raw Range Max.
                                        </div>
                                        <div class="tdy-required" *ngIf="TXD_RAW_DATA_VALUE.hasError('min')">
                                            Discrete Value should not be less than 0.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class="edit_input">
    
  {{discData.TXD_RAW_DATA_VALUE}} 
                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>
                                        <div class="tdy-required" *ngIf="bitLengthBool === true && di === minIndex">
                                            Discrete Value already exists.
                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td pEditableColumn class="text-left">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input type="text" pInputText [ngModel]="discData.TXD_DESCRIPTION"
                                            (ngModelChange)="discData.TXD_DESCRIPTION = $event.toUpperCase()"
                                            [style]="{'text-transform':'uppercase'}" name="TXD_DESCRIPTION" required
                                            id="TXD_DESCRIPTION" #textdsp="ngModel"
                                            [disabled]="SelectedEuType?.value === 0 || !SelectedEuType ? true : false" />
                                        <div class="tdy-required"
                                            *ngIf="( textdsp.dirty  || textdsp.touched) && textdsp.hasError('required')">
                                            Discrete Text is required.
                                        </div>
                                        <div class="tdy-required" *ngIf="textdsp.hasError('pattern')">
                                            Discrete Text cannot be left blank.
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                         <div class="edit_input">
     {{discData.TXD_DESCRIPTION }} 

                                            <i class="edit_icon material-symbols-outlined">
                                                edit
                                            </i>
                                        </div>
                                        <div class="tdy-required" *ngIf="isCellEmpty(discData.TXD_DESCRIPTION)">
                                            Discrete Text is required.
                                        </div>
                                    </ng-template>
                                </p-cellEditor>

                            </td>
                            <td style="text-align: right !important;"> <button id="TXD_del_Button" name="TXD_del_Button"
                                    type="button" class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash"
                                    [pTooltip]="((editedDiscValue>discInputValue) || (discData.TXD_RAW_DATA_VALUE==null) || (discData.TXD_DESCRIPTION=='')  || (bitLengthBool === true))?'Enter valid data...':'Delete...'"
                                    [disabled]="((editedDiscValue>discInputValue) || (discData.TXD_RAW_DATA_VALUE==null) || (discData.TXD_DESCRIPTION=='')  || (bitLengthBool === true))"
                                    (click)="openDeleteDialog(discData,di)" *ngIf="userCanManage"> </button></td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
        </p-accordion>
    </div>
</dynamic-form>