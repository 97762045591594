<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div *ngIf="model != null" class="col-12 card">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
        <div>
            <span class="p-input-icon-align">
                <button (click)="onClickAdd()" *ngIf="userCanAdd" icon="pi pi-plus" id="addButton" label="Add" pButton
                pRipple type="button"></button>
            </span>
            <span class="p-input-icon-align  mr-0">
                    <button (click)="dt.exportCSV()" [disabled]="downloadButtonDisabled" icon="pi pi-download" id="downloadButton"  pTooltip="Download" pButton pRipple type="button"></button>
            </span>
        </div>
    </div>
    <div class="col-12">
        <p-table #dt
        [filterDelay]="0" 
        [responsive]="true" 
        [sortOrder]="1" 
        [columns]="cols" 
        exportFilename="{{dynamicdownloadName}}"
        [value]="model" 
        [dataKey]="dataKey" 
        id="ListTable" 
        selectionMode="single"
        (onRowSelect)="onRowSelect($event)" 
        [showCurrentPageReport]="true" 
        [paginator]="model.length > defaultTableRows" 
        [rowsPerPageOptions]="[20,50,100,200]" 
        [rows]="defaultTableRows" 
        currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
        sortField="name">
            <ng-template pTemplate="header">
                <tr >
                    <th pSortableColumn="name" style="width:94%">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Name 
                            <p-sortIcon field="name"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class=""
                                display="menu" field="name" header="name" type="text" matchMode="contains"></p-columnFilter>
                           
                          
                        </div>
                    </th>
                    <!-- <th style="width:15%">Versions</th> -->
                    <th style="width:2%" *ngIf="userCanModify"></th>
                    <th style="width:2%" *ngIf="modelName == 'dataframe'"></th>
                    <th style="width:2%" *ngIf="userCanDelete"></th>
                   
                   </tr>
            </ng-template>
            <ng-template let-row pTemplate="body">
                <ng-container *ngIf="toModel(row); let row">
                    <tr [pSelectableRow]="row">
                        <td>
                            {{row.name}}
                        </td>
                        <!-- <td >
                            <div class="flex justify-content-start">
                                <button id="manageButton" name="manageButton" type="button"
                                    class="p-button-rounded p-button-text " pButton pRipple
                                    icon="pi pi-vimeo" pTooltip="Versions..." 
                                    (click)="onShowVersions(row)"></button>
                            </div>
                            
                        </td> -->
                        <td *ngIf="userCanModify">
                            <div class="flex justify-content-end">
                                <button id="manageButton" name="manageButton" type="button"
                                    class="p-button-rounded p-button-text " pButton pRipple
                                    icon="pi pi-cog" pTooltip="Manage..." [disabled]="!userCanModify"
                                    (click)="onManageRecord(row)"></button>
                            </div>
                        </td>

                        <td *ngIf="modelName == 'dataframe'">
                            <div class="flex justify-content-center">
                                <button id="compareButton" name="compareButton" type="button"
                                    class="p-button-rounded p-button-text " pButton pRipple
                                    icon="pi pi-tags" pTooltip="Compare..." 
                                    (click)="onCompareDataFrames(row)"></button>
                            </div>
                        </td>

                        <td *ngIf="userCanDelete" >
                            <div class="flex justify-content-start">
                                <button id="deleteButton" name="deleteButton" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash" pTooltip="Delete..." [disabled]="!userCanDelete"
                                    (click)="onDeleteRecord(row)"></button>
                            </div>
                        </td>
                       
                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">{{NoRecordMessage}}</td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="model.length > 0 && defaultTableRows >= model.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{model.length}} of {{model.length}} {{pageTitle}}</p>
          </div>
    </div>
</div>