import { Component, OnInit, NgZone } from '@angular/core';
import { Components } from '../../../integration/endpointdatadelivery.components';
import { Observable, catchError, of } from 'rxjs';
import { EndPoints } from 'src/app/features/datadelivery/datadelivery/models/ConfigEndPoints';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EndpointDataDeliveryEndPointService } from '../../../services/endpoint.service';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';

@Component({
  selector: 'app-endpoints-data-list',
  templateUrl: './endpoints-data-list.component.html',
  styleUrls: ['./endpoints-data-list.component.scss'],
})
export class EndpointsDataListComponent
  extends BaseViewComponent
  implements OnInit {
  public endpoints$: Observable<EndPoints[]>;
  messageLabel = 'Endpoint';
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private endpointDataDeliveryEndPoints: EndpointDataDeliveryEndPointService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.loadEndpoint();
  }
  loadEndpoint() {
    // TODO: use right endpoint when it will be available
    this.endpoints$ = this.endpointDataDeliveryEndPoints.getEndPoints().pipe(
      catchError((err) => {
        this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}s`);
        return of([]);
      })
    );
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.EndpointDataDelivery.label,
        routerLink: Components.EndpointDataDelivery.path,
      },
    ]);
  }

  onRowSelect(event) {
    this.ngZone.run(() => {
      this.router.navigate([Components.ViewEndpointDataDelivery.path], {
        state: { endptdata: event },
        queryParams: {
          endpointId: event.endpoint_id,
          name: event.name,
        },
      });
    });
  }
}
