import {
  FormGroup,
  FormControl,
  FormArray,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { getProp, isNumber, isString } from '../utils';

export class ValidationHelper {
  public static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((element) => {
          if (element instanceof FormGroup) {
            this.validateAllFormFields(element);
          }
        });
      }
    });
  }

  public static conditionalValidator(predicate: any, validator: any) {
    return (formControl: { parent: any }) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return validator(formControl);
      }
      return null;
    };
  }

  public static onSearchMnemonic(dataparameter): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isFound = dataparameter.some((element) => {
        if (element.Mnemonic_Code == control.value) {
          return true;
        }
        return false;
      });
      return isFound ? { exists: true } : {};
    };
  }

  public static onSearchName(getNameArray: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!getNameArray) return;
      const name = control.value.trim();
      let isFound;
      if (name != '') {
        isFound = getNameArray.includes(name);
      }
      return isFound ? { exists: true } : { exists: false };
    };
  }

  public static validateString(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return !isString(control.value) && control.value
        ? { invalidString: { message: ' should be String.' } }
        : null;
    };
  }

  public static validateInteger(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return (((control.value) * (10.0)) % 10) != 0 && isNumber(control.value)
        ? { invalidInteger: { message: ' should be an Integer.' } }
        : null;
    };
  }

  public static validateNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return (!isNumber(control.value) && control.value)
        ? { invalidNumber: { message: ' should be Numeric.' } }
        : null;
    };
  }

  public static validateSumLte(obj: any, prop1: string, prop2: string, prop1Label: string, prop2Label: string, max: number): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return getProp(obj, prop1) + getProp(obj, prop2) > max
        ? { sumLte: { message: `Sum of ${prop1Label} and ${prop2Label} should not be greater than ${max}.` } }
        : null;
    };
  }
}
