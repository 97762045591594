import { Component, OnInit, NgZone } from '@angular/core';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { Components } from '../../../integration/endpointdatadelivery.components';
import { Observable, catchError, of, startWith, takeUntil } from 'rxjs';
import { FlightDataRedactions } from 'src/app/features/datadelivery/datadelivery/models/FlightDataRedactions';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EndpointFlightDataRedactionService } from '../../../services/flightDataRedaction.service';

@Component({
  templateUrl: './data-redactions-list.component.html',
  styleUrls: ['./data-redactions-list.component.scss'],
})
export class DataRedactionsListComponent
  extends BaseViewComponent
  implements OnInit {
  pageTitle = Components.DataRedactions.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  flightDataRedactions$: Observable<FlightDataRedactions[]>;
  messageLabel = 'Flight Data Redaction';

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private endpointFlightredactionservice: EndpointFlightDataRedactionService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.DataRedactions.label },
    ]);
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.loadRedactionData();
  }

  onRowClick(event, e: any) {
    e.preventDefault();
    e.stopPropagation();

    this.ngZone.run(() => {
      this.router.navigate([Components.DataRedactionDetails.path], {
        state: { flightData: event },
        queryParams: {
          redactionId: event.redaction_id,
          name: event.name
        },
      });
    });
  }

  loadRedactionData() {
    this.flightDataRedactions$ = this.endpointFlightredactionservice
      .getFligtRedactions()
      .pipe(
        startWith([]),
        takeUntil(this.ngUnsubscribe),
        catchError((err) => {
          this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}s`);
          return of([]);
        })
      );
  }
}
