import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ng2-cookies';
import { AppComponent } from './app.component';
import { CoreModule } from './platform/core.module';
import { AircraftService } from './features/administration/services/aircraft.service';
import { UserService } from 'src/app/features/administration/services/user.service';
import { PageNotFoundComponent } from './platform/components/page-not-found.component';
import { AccessDeniedComponent } from './platform/components/access-denied.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppRoutingModule } from './app.routing.module';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
// import {FullCalendarModule} from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { GMapModule } from 'primeng/gmap';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist'
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { SettingsService } from './common/services/settings.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SharedModule } from './platform/shared.module';
import { BreadcrumbService } from './common/services/breadcrumb.service';
import { BreadcrumbComponent } from 'src/app/platform/components/breadcrumb/breadcrumb.component';
import { MenuComponent } from './platform/components/menu.component';
import { MainComponent } from 'src/app/platform/components/main/main.component';
import { MenuItemComponent } from './platform/components/menu-item.component';
import { MenuService } from './common/services/menu.service';
import { RightMenuComponent } from 'src/app/platform/components/right-menu/rightmenu.component';
import { UnsavedChangesGuard } from './platform/guards/unsaved-changes.guard';
import { SecurityModule } from './security/security.module';
import { AirlineService } from './features/administration/services/airline.service';
import { DataFrameService } from './features/configuration/services/data-frame.service';
import { TransferdataService } from './features/configuration/services/transferdata.service';
import { FeatureRoot } from './common/models/feature-item.model';
import { administrationMenu } from './features/administration/integration/administration.menu';
import { configurationMenu } from './features/configuration/integration/configuration.menu';
import { datadeliveryMenu } from './features/datadelivery/datadelivery/integration/datadelivery.menu'
//import { sendtoteledyneMenu } from './features/sendtoteledyne/integration/sendtoteledyne.menu';
import { endpointdatadeliveryMenu } from './features/datadelivery/endpointdatadelivery/integration/endpointdatadelivery.menu'

const featureMenus: FeatureRoot[] = [
  administrationMenu,
  configurationMenu,
  datadeliveryMenu,
  //sendtoteledyneMenu,
  endpointdatadeliveryMenu
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgIdleKeepaliveModule.forRoot(),
    SharedModule,
    BlockUIModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DragDropModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    // FullCalendarModule,
    GalleriaModule,
    GMapModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    KnobModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SecurityModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TagModule,
    TerminalModule,
    TimelineModule,
    TieredMenuModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    // Routes get loaded in order. It is important that modules
    // come before AppRoutingModule, as AppRoutingModule defines the catch-all ** route
    AppRoutingModule
  ],
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    BreadcrumbComponent,
    MainComponent,
    MenuComponent,
    MenuItemComponent,
    RightMenuComponent
  ],
  providers: [
    AirlineService,
    UnsavedChangesGuard,
    ConfirmationService,
    AircraftService,
    CookieService,
    SettingsService,
    UserService,
    BreadcrumbService,
    MenuService,
    MessageService,
    DataFrameService,
    TransferdataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    // ARO: need to execute here rather than APP_INITIALIZER.
    // Otherwise timing issue in RouteSecurityService.
    // Fixes unnecessary login redirect on refresh.
    MenuService.loadFeatures(featureMenus);
  }
}
