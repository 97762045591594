import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { ClientPackagesService } from '../../datadelivery/services/clientPackage.service';

@Injectable({
    providedIn: 'root'
})
export class EndpointClientPackagesService extends ClientPackagesService {

    constructor(
        public override http: HttpClient) {
        super(http);
        this.baseUrl = environment.apiUrl.endpointdatadelivery;
    }

}
