import { Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Parameter, Frame, parameters, ParameterList } from 'src/app/features/configuration/models/dataframe717-767';
import { TransferdataService } from 'src/app/features/configuration/services/transferdata.service';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action } from 'src/app/common/components/base-view.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Components } from 'src/app/features/configuration/integration/configuration.components';
import { ListView } from '../listview.component';

@Directive()
export class Listview767AndDXSComponent extends ListView {
  pageTitle = Components.ListView767.label;
  dataparameter: Parameter[] = [];
  frameIdentification: Frame[] = [];
  jsonArray: parameters[] = [];

  updatedFrame: any;
  updatedMnemonic: any;
  datatablecopy = [];

  emptyMessage = 'No 767 Data Frame Found.';

  constructor(
    router: Router,
    appService: TransferdataService,
    dataFrameService: DataFrameService,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    realUserService: SecurityUserService
  ) {
    super(
      messageService,
      confirmationService,
      router,
      breadcrumbService,
      route,
      appService,
      dataFrameService,
      realUserService
    );

  }
  getDataFrame(versionId: number) {
    this.loadingCount++;
    this.dataFrameService
      .IdentifyDataFrame(versionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => {
          const response = JSON.parse(data.content);
          if (response["ARINC STANDARD"] === 'DXS') {
            this.dataFrameMapView = Components.MapViewDXS;
          } else {
            this.dataFrameMapView = Components.MapView767;
          }
          this.getListView(response);
        },
        error: (err) => {
          this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--,
      });
  }

  getListView(response) {
    this.frameIdentification = [];
    this.dataparameter = [];
    this.jsonArray = [];
    const updatedJSON = response;
    const [fredType, parameterType] = this.getFREDType(updatedJSON);

    this.dataFrame = response;
    for (const key in updatedJSON.FREDFile[fredType].Frame) {
      for (const key1 in updatedJSON.FREDFile[fredType].Frame[key]) {
        this.frameIdentification.push(updatedJSON.FREDFile[fredType].Frame[key][key1]);
      }
    }

    for (const key in updatedJSON.FREDFile[fredType][parameterType]) {
      for (const key1 in updatedJSON.FREDFile[fredType][parameterType][key]) {
        this.dataparameter.push(updatedJSON.FREDFile[fredType][parameterType][key][key1]);
      }
    }

    for (const key in updatedJSON.FREDFile[fredType].BusList) {
      for (const key1 in updatedJSON.FREDFile[fredType].BusList[key]) {
        this.dataparameter.push(updatedJSON.FREDFile[fredType].BusList[key][key1]);
      }
    }
    this.updatedMnemonics();
    this.loadingCount--;
  }

  deleteRecord(deleteRow: any) {
    this.deleteMnemonic({
      Mnemonic_Code: deleteRow.Schema767.Mnemonic_Code,
      Name: deleteRow.Schema767.Name,
    });
  }

  override resetDataFrame() {
    this.loadingCount++;
    super.resetDataFrame();
  }

  onDPUpdates() {
    this.dataparameter.forEach((val) => {
      for (const value of this.frameIdentification) {
        if (value.Parameter_List?.Name_Ref?.includes(val.Name) === true) {
          const obj = {
            Frame_Number: value.Parameter_List.Name_Ref.indexOf(val.Name) + 1,
            Frame: value,
            Schema767: val,
            Frame_Rate: Object.prototype.hasOwnProperty.call(value.Recording_Rate, 'Denominator')
              ? parseFloat((value.Recording_Rate.Numerator / value.Recording_Rate.Denominator).toFixed(2))
              : value.Recording_Rate.Numerator,
          };
          this.jsonArray.push(obj);
        }
      }
    });
  }

  getFREDType(obj: any): [string,string] {
    return obj['ARINC STANDARD'] === 'A767' ? ['FRED767', 'Parameter767'] : ['FREDDXS', 'ParameterDXS'];
  }

  updatedFrameDetails() {
    this.showMessage = true;
    this.updatedFrame = JSON.parse(sessionStorage.getItem('FrameDetails'));
    const updatedframe = this.updatedFrame;
    const [fredType, _] = this.getFREDType(updatedframe);
    this.frameIdentification = [];
    this.dataFrame.FREDFile[fredType].Header = this.updatedFrame.FREDFile[fredType].Header;
    this.dataFrame.FREDFile[fredType].Recorder_File = this.updatedFrame.FREDFile[fredType].Recorder_File;
    this.dataFrame.FREDFile[fredType].Frame = this.updatedFrame.FREDFile[fredType].Frame;
    for (const key in this.updatedFrame.FREDFile[fredType].Frame) {
      for (const key1 in this.updatedFrame.FREDFile[fredType].Frame[key]) {
        this.frameIdentification.push(this.updatedFrame.FREDFile[fredType].Frame[key][key1]);
      }
    }
    sessionStorage.setItem('FrameDetails', JSON.stringify(updatedframe));
  }

  updatedParameters() {
    const [fredType, parameterType] = this.getFREDType(this.dataFrame);
    const mnemonicsArray = JSON.parse(sessionStorage.getItem('Mnemonics'));
    for (const key in mnemonicsArray) {
      for (const key1 in mnemonicsArray[key]) {
        for (const key2 in mnemonicsArray[key][key1]) {
          const name = mnemonicsArray[key][key1][key2].Schema767.Mnemonic_Code;
          if (key1 === 'Edit') {
            this.dataparameter[key2] = mnemonicsArray[key][key1][key2].Schema767;
            this.dataFrame.FREDFile[fredType][parameterType][key2] = {
              [name]: mnemonicsArray[key][key1][key2].Schema767,
            };
          }
          if (key1 === 'Clone') {
            this.frameIdentification.forEach((value) => {
              if (value.Frame_ID === mnemonicsArray[key][key1][key2].Frame.Frame_ID) {
                if (!value.Parameter_List) {
                  value.Parameter_List = { Name_Ref: [] } as ParameterList
                }
                value.Parameter_List.Name_Ref.push(mnemonicsArray[key][key1][key2].Schema767.Name);
              }
            });
            this.dataparameter.push(mnemonicsArray[key][key1][key2].Schema767);
            this.dataFrame.FREDFile[fredType].Frame.forEach((value) => {
              if (value.Frame_ID === mnemonicsArray[key][key1][key2].Frame.Frame_ID) {
                if (!value.Parameter_List) {
                  value.Parameter_List = { Name_Ref: [] } as ParameterList
                }
                value.Parameter_List.Name_Ref.push(mnemonicsArray[key][key1][key2].Schema767.Name);
              }
            });
            this.dataFrame.FREDFile[fredType][parameterType].push({
              [name]: mnemonicsArray[key][key1][key2].Schema767,
            });
          }
          if (key1 === 'Add') {
            this.frameIdentification.forEach((value) => {
              if (value.Frame_ID === mnemonicsArray[key][key1][key2].Frame.Frame_ID) {
                if (!value.Parameter_List) {
                  value.Parameter_List = { Name_Ref: [] } as ParameterList
                }
                value.Parameter_List.Name_Ref.push(mnemonicsArray[key][key1][key2].Schema767.Name);
              }
            });
            this.dataparameter.push(mnemonicsArray[key][key1][key2].Schema767);
            this.dataFrame.FREDFile[fredType].Frame.forEach((value) => {
              if (value.Frame_ID === mnemonicsArray[key][key1][key2].Frame.Frame_ID) {
                value.Parameter_List.Name_Ref.push(mnemonicsArray[key][key1][key2].Schema767.Name);
              }
            });
            this.dataFrame.FREDFile[fredType][parameterType].push({
              [name]: mnemonicsArray[key][key1][key2].Schema767,
            });
          }
        }
      }
    }
  }

  onMnemonicDelete() {
    const [fredType, parameterType] = this.getFREDType(this.dataFrame);
    this.deletedMnemonics = JSON.parse(sessionStorage.getItem('DeletedMnemonics'));
    this.deletedMnemonics.forEach((element) => {
      for (const key in this.dataFrame.FREDFile[fredType].Frame) {
        for (const key1 in this.dataFrame.FREDFile[fredType].Frame[key]) {
          this.dataFrame.FREDFile[fredType].Frame[key][key1].Parameter_List.Name_Ref.forEach((name) => {
            if (name === element.Name) {
              const index = this.dataFrame.FREDFile[fredType].Frame[key][key1].Parameter_List.Name_Ref.indexOf(
                element.Name
              );
              this.dataFrame.FREDFile[fredType].Frame[key][key1].Parameter_List.Name_Ref.splice(index, 1);
            }
          });
        }
      }
      for (const key in this.dataFrame.FREDFile[fredType][parameterType]) {
        for (const key1 in this.dataFrame.FREDFile[fredType][parameterType][key]) {
          if (this.dataFrame.FREDFile[fredType][parameterType][key][key1].Mnemonic_Code === element.Mnemonic_Code) {
            this.dataFrame.FREDFile[fredType][parameterType].splice(key, 1);
          }
        }
      }
      this.dataparameter.splice(
        this.dataparameter.findIndex((e) => e.Mnemonic_Code === element.Mnemonic_Code),
        1
      );
      this.jsonArray.splice(
        this.jsonArray.findIndex((e) => e.Schema767.Mnemonic_Code === element.Mnemonic_Code),
        1
      );
      this.datatablecopy = Object.assign([], this.jsonArray);
      this.datatablecopy.slice(
        this.datatablecopy.findIndex((e) => e.PRM_MNEMONIC === element),
        1
      );
      this.jsonArray = this.datatablecopy;
      this.updatedMnemonic = JSON.parse(sessionStorage.getItem('Mnemonics'));
      for (const key in this.updatedMnemonic) {
        for (const key1 in this.updatedMnemonic[key]) {
          for (const key2 in this.updatedMnemonic[key][key1]) {
            if (this.updatedMnemonic[key][key1][key2].Mnemonic_Code === element.Mnemonic_Code) {
              this.updatedMnemonic.splice(key, 1);
            }
          }
        }
      }
    });
    sessionStorage.setItem('DeletedMnemonics', JSON.stringify(this.deletedMnemonics));
    sessionStorage.removeItem('Mnemonics');
    sessionStorage.setItem('Mnemonics', JSON.stringify(this.updatedMnemonic));
  }
}
