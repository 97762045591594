import {Component, Input} from '@angular/core';
import {FeatureItem} from "../../../../../../common/models/feature-item.model";
import {Router} from "@angular/router";
import {DataFrameConstants} from "../shared/constants/constants";
import {BaseViewComponent} from "../../../../../../common/components/base-view.component";
import {ConfirmationService, MessageService} from "primeng/api";
import {BreadcrumbService} from "../../../../../../common/services/breadcrumb.service";

interface IAnalyseColumn {
  field: string,
  header: string,
  type: string,
  sortable: boolean
}

@Component({
  selector: 'app-data-frame-analyse',
  templateUrl: './data-frame-analyse.component.html',
  styleUrls: ['./data-frame-analyse.component.scss']
})
export class DataFrameAnalyseComponent extends BaseViewComponent {
  public dataFrameAnalyse: FeatureItem;
  public frameId: number;
  public versionId: number;
  public dataFrame: any;
  public messageLabel = 'Data Frame Analyse';
  protected readonly DataFrameConstants = DataFrameConstants;

  @Input() set dataFrameAnalyseValue(dataFrame: FeatureItem) {
    this.dataFrameAnalyse = dataFrame;
  }

  @Input() isLoading: boolean;
  @Input() errors: any[];
  @Input() errorMessages: any[];
  @Input() mapViewPath: string;
  @Input() listViewPath: string;
  @Input() editPath: string;

  public columns: Array<IAnalyseColumn> = [
    { field: 'Mnemonic', header: 'Mnemonic', type: 'default', sortable: true},
    { field: 'Attribute', header: 'Attribute', type: 'default', sortable: true},
    { field: 'Error', header: 'Errors', type: 'default', sortable: false },
  ];

  constructor(router: Router,
              breadcrumbService: BreadcrumbService,
              messageService: MessageService,
              confirmationService: ConfirmationService,
              ) {
    super(messageService, confirmationService, router, breadcrumbService )
  }

  public navigateToParameter(parameter: any): void {
    this.breadcrumbService.navigateTo(this.editPath , undefined, undefined,{ editName: parameter.data.Mnemonic })
  }

  public returnToPreviousPage(): void {
    this.breadcrumbService.navigateTo(this.breadcrumbService.getQueryParam('isMapView') ? this.mapViewPath : this.listViewPath)
  }
}
