import { FeatureRoot } from 'src/app/common/models/feature-item.model';
import { Components } from './endpointdatadelivery.components';
import { permissions } from './endpointdatadelivery.permissions';

export const endpointdatadeliveryMenu: FeatureRoot = {
  label: 'Endpoint Data Delivery',
  icon: 'hub',
  user_management: {
    feature_name: 'Endpointdatadelivery',
    user_permission: permissions.endpointdatadelivery_users.view,
    roles_permission: permissions.endpointdatadelivery_roles.view,
  },
  items: [
    {
      label: 'Endpoint Data Delivery',
      icon: 'hub',
      items: [
        Components.EndpointDataDelivery,
        Components.DataRedactions,
        Components.ACMSReportRedactionsDataDelivery,
        Components.SARRedactionsDataDelivery,
        Components.ClientpackagesDataDelivery,
      ],
    },
  ],
};
