// Environment file for running under AWS INFRA env
// NOTE: Since we don't have Okta infra instance this just points to Okta dev for now.

const aws_api_url = 'https://api-spectruminfradev.teledynecontrols.com/';

export const environment = {
  configurationName: "infrastructure",
  production: false,
  oktaUrl: 'https://dev-64878223.okta.com',
  oktaClientAppId: '0oaatfpztcxU8zXok5d7',
  apiBase: aws_api_url,
  apiUrl: {
    'admin': aws_api_url + 'administration/',
    'config': aws_api_url + 'configuration/',
    'datadelivery': aws_api_url + 'data-delivery/',
    'security': aws_api_url + 'security/',
    'common': aws_api_url + 'common/',
    'sendToTdy': aws_api_url + 'send-to-tdy/',
    'endpointdatadelivery': aws_api_url + 'endpoint-data-delivery/',
  }
};
