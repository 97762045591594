import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { aircrafts, ConversionType, DataType, Redaction, ZipType, flightDataType, EndPoints } from '../../models/ClientPackage';
import { EnhancedPackages, ResponseModelEnhancedPackages, ConversionTypes } from '../../models/EnhancedPackage';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  NgForm,
} from '@angular/forms';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { BaseViewComponent, Action } from 'src/app/common/components/base-view.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DataDeliveryEndPointService } from '../../services/endpoint.service';
import { forkJoin, takeUntil } from 'rxjs';
import { ClientPackagesService } from '../../services/clientPackage.service';
import { EnhancedPackagesService } from '../../services/enhancedPackage.service';
import { FlightDataRedactionService } from '../../services/flightDataRedaction.service';
import { EnhancedRedactionService } from '../../services/enhancedRedaction.service';
import { CommonService } from '../../../../../common/services/common.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
import { AircraftRecorderService } from 'src/app/features/configuration/services/aircraftRecorder.service';
import { AircraftGroup } from "src/app/features/administration/models/aircraftGroup";
import { SelectItem } from 'primeng/api';
import { AircraftGroupService } from 'src/app/features/administration/services/aircraft-group.service';
import { TeledynePackagesService } from 'src/app/features/sendtoteledyne/services/teledynepackages.service';
@Component({
  selector: 'app-clientpackage-add',
  templateUrl: './enhancedpackage-details.component.html',
  styleUrls: ['./enhancedpackage-details.component.css']
})
export class EnhancedClientPackageAddComponent extends BaseViewComponent implements OnInit {
  @ViewChild('clientpackageForm') clientpackageForm!: NgForm;
  pageTitle = Components.AddEnhancedPackages.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  isDataFrame = false;
  today = new Date();
  // todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  todayAsStr = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`
  hour = String(this.today.getHours()).padStart(2, '0')
  min = String(this.today.getMinutes()).padStart(2, '0')
  seconds = String(this.today.getSeconds()).padStart(2, '0')
  cols: any[] = [];
  clientpackages: EnhancedPackages[] = [];
  clientpackage!: EnhancedPackages;
  redactionType: Redaction[] = [];
  dataType: DataType[] = [];
  zipType: ZipType[] = [];
  flightdataType: flightDataType[] = [];
  endpoints: EndPoints[] = [];
  conversionType: ConversionType[] = [];
  uniqueError = false;
  filesarray: any[] = [];
  pattern!: any;
  encryptedtext: any;
  encryptfilename: any;
  encryptfilepassword: any;
  encrypted!: boolean;
  year!: number;
  month!: string;
  day!: string;
  opt_name!: string;
  availableEndPoints: EndPoints[];
  availableAircraft: aircrafts[] = [];
  selectedEndPoints: EndPoints[] = [];
  selectedAircraft: aircrafts[] = [];
  selectedEndPointsIDs: number[] = [];
  selectedAircraftIDs: number[] = [];
  responseModelClientPackages: ResponseModelEnhancedPackages;
  picklistChange = false;
  pickAircraftlistChange = false;
  isButtonDisabled = true;
  isAircraftButtonDisabled = true;
  initialEndpointsCount: number;
  initialAircraftsCount: number;
  saveButtonDisabled: boolean;
  isReadonly = true;
  messageLabel = "enhanced package";
  showButtons = true;
  isPrimary: boolean;
  isSecondary: boolean;
  isEnabled: boolean;
  selectedPredefinedId: number;
  selectedRedactionId: number;
  defaultfileName: string;
  isRedaction = true;
  noConversionId = 1;
  isNoConversion = true;
  file_Name = `%Reg%%Source%%YYYY%%MM%%DD%%HH%%mm%%ss%`;
  newLabel = "New Enhanced Package";
  aircraftGroups: SelectItem[];
  selectedAircraftGroupIDs: aircrafts[] = [];
  targetAircraft: aircrafts[] = [];
  masterAircraft: aircrafts[] = [];
  selectedAircraftGroup: number;
  selectedTargetAircraftGroup: number;
  constructor(router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService, confirmationService: ConfirmationService,
    messageService: MessageService, private datadeliveryEndPointservice: DataDeliveryEndPointService,
    private clientPackagesService: ClientPackagesService, private flightDataRedactionService: FlightDataRedactionService,
    private enhancedRedactionService: EnhancedRedactionService,
    private commonService: CommonService, private realUserService: SecurityUserService, private enhancedPackagesService: EnhancedPackagesService,
    private aircraftGroupService: AircraftGroupService,
    private aircraftRecorderService: AircraftRecorderService,
    private teledynePackagesService: TeledynePackagesService) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [{ label: Components.EnhancedPackages.label, routerLink: Components.EnhancedPackages.path }, {
      label: Components.AddEnhancedPackages.label + ` (${this.route.snapshot.queryParamMap.get('name')})`
    }])
  }

  intialVal() {
    this.isNew = true;
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.clientpackage = new EnhancedPackages;
    const date = new Date();
    this.todayAsStr = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    this.clientpackage.package_id = -1;
  }
  packageForm!: FormGroup;

  ngOnInit() {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_packages.create);
    this.canEdit = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_packages.manage);
    this.canDelete = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_packages.delete);
    this.year = new Date().getFullYear();
    this.month = String(new Date().getMonth() + 1).padStart(2, '0');
    this.day = String(new Date().getDate()).padStart(2, '0');
    this.opt_name = `^${this.year}\-${this.month}\-${this.day}\-[a-zA-Z0-9]*\-[a-zA-Z0-9]*\-[a-zA-Z0-9]*\-[a-zA-Z0-9]*\-[a-zA-Z0-9]*$`;
    this.pattern = `^[a-zA-Z0-9]*\-[a-zA-Z0-9]*\-${this.todayAsStr}-${this.hour}-${this.min}-${this.seconds}$`;
    const packageId = +this.route.snapshot.queryParamMap.get('packageId');
    const isClone = !!this.route.snapshot.queryParamMap.get('clone');
    if (packageId) {
      if (isClone) {
        this.isNew = true;
        !this.canAdd ? this.isReadonly = true : this.isReadonly = false;
        !this.canAdd ? this.showButtons = false : this.showButtons = true;
        this.pageTitle = 'Clone Enhanced Package';
        this.updateBreadcrumb(`${this.pageTitle} (${this.route.snapshot.queryParamMap.get('name')})`);
      } else {
        this.isNew = false;
        !this.canEdit ? this.isReadonly = true : this.isReadonly = false;
        !this.canEdit ? this.showButtons = false : this.showButtons = true;
      }
      this.loadClientPackages(packageId, isClone);
    } else {
      !this.canAdd ? this.isReadonly = true : this.isReadonly = false;
      !this.canAdd ? this.showButtons = false : this.showButtons = true;
      this.intialVal();
      this.loadDataFrames();
    }

  }

  toendpoints(endpoint: EndPoints): EndPoints {
    return endpoint;
  }

  loadClientPackages(packageId: number, clone: boolean) {
    this.loadingCount++;
    this.redactionType = [];
    forkJoin({
      getPackages: this.enhancedPackagesService.getClientPackage_by_id(packageId),
      avialableEndPoints: this.datadeliveryEndPointservice.getEndPoints(),
      availableConvertionType: this.clientPackagesService.getConvertionTypes(),
      redactionType: this.enhancedRedactionService.getFligtRedactions(),
      ziptypes: this.flightDataRedactionService.getZipTypes(),
      aircraftgroups: this.aircraftGroupService.listAircraftGroups(),
      availableAircraft: this.commonService.listAircraftGroupIdFilters(0)
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: results => {
        this.zipType = results.ziptypes;
        this.clientpackage = results.getPackages;
        this.clientpackage.primary = results.getPackages.primary == true ? true : false;
        this.clientpackage.secondary = results.getPackages.secondary == true ? true : false;
        if (this.clientpackage.redaction_id == this.noConversionId || this.clientpackage.redaction_id == null) { this.isRedaction = false; }
        this.conversionType = results.availableConvertionType;

        results.redactionType.forEach(ele => {
          this.redactionType.push({ redaction_id: ele.redaction_id, redaction_type: ele.name })
        });
        this.selectedRedactionId = this.clientpackage.redaction_id;
        this.availableAircraft = [];
        this.availableEndPoints = [];

        //Endpoints
        this.clientpackage.endpoints.forEach(ele => {
          if (!this.selectedEndPoints.find(a => a.endpoint_id == ele.endpoint_id)) {
            this.selectedEndPoints.push({ endpoint_id: ele.endpoint_id, endpoint_name: ele.endpoint_name })
          }
        });
        this.clientpackage.endpoints = [];
        this.clientpackage.endpoints = this.selectedEndPoints;
        results.avialableEndPoints.forEach(ele => {
          this.availableEndPoints.push({ endpoint_id: ele.endpoint_id, endpoint_name: ele.name })
        });
        this.selectedEndPoints.forEach(ele => {
          this.selectedEndPointsIDs.push(ele.endpoint_id)
        })
        if (this.availableEndPoints && this.selectedEndPoints) {
          this.availableEndPoints = this.availableEndPoints.filter(p => this.selectedEndPoints.findIndex(i => i.endpoint_id == p.endpoint_id) == -1)
        }
        this.clientpackage.endpoints ? this.initialEndpointsCount = this.clientpackage.endpoints.length : this.initialEndpointsCount = 0;
        this.initialEndpointsCount = this.selectedEndPoints.length;

        //Aircraft
        this.aircraftGroups = this.teledynePackagesService.populateDropdownItems<AircraftGroup>(results.aircraftgroups, 'aircraftgroup_name', 'aircraftgroup_id');
        this.targetAircraft = [];

        this.clientpackage.aircrafts.forEach((ele) => {
          this.selectedAircraft.push({
            aircraft_id: ele.aircraft_id,
            aircraft_registration: ele.aircraft_name,
          });
        });

        results.availableAircraft.forEach((ele) => {
          if (this.selectedAircraft.find(a => a.aircraft_registration == ele.aircraft_registration) && !this.targetAircraft.find(a => a.aircraft_registration == ele.aircraft_registration)) {
            this.targetAircraft.push({
              aircraftgroup_id: ele.aircraftgroup_id,
              aircraft_id: ele.aircraft_id,
              aircraft_registration: ele.aircraft_registration,
            });
          }
          if (!this.availableAircraft.find(a => a.aircraft_registration == ele.aircraft_registration)) {
            this.availableAircraft.push({
              aircraftgroup_id: ele.aircraftgroup_id,
              aircraft_id: ele.aircraft_id,
              aircraft_registration: ele.aircraft_registration,
            });
          }
        });
        this.targetAircraft.forEach(ele => {
          this.selectedAircraftGroupIDs.push({ aircraft_id: ele.aircraft_id, aircraft_registration: ele.aircraft_registration, aircraftgroup_id: ele.aircraftgroup_id });
        })

        if (this.availableAircraft && this.targetAircraft) {
          this.availableAircraft = this.availableAircraft.filter(p => this.selectedAircraft.findIndex(i => i.aircraft_registration == p.aircraft_registration) == -1)
        }
        this.clientpackage.aircrafts ? this.initialAircraftsCount = this.clientpackage.aircrafts.length : this.initialAircraftsCount = 0;
        this.initialAircraftsCount = this.selectedAircraft.length;
        this.availableAircraft.sort((a, b) => { return a.aircraft_registration.localeCompare(b.aircraft_registration) });
        this.targetAircraft.sort((a, b) => { return a.aircraft_registration.localeCompare(b.aircraft_registration) });
        if (clone) {
          this.clientpackage.name = "";
        }
      },
      error: error => {
        this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
      }
    })
  }


  onMoveToTarget(event) {
    event.items.forEach(ele => {
      this.selectedEndPointsIDs.push(ele.endpoint_id);
    });
    this.isButtonDisabled = false;
    this.selectedEndPointsIDs = [...new Set(this.selectedEndPointsIDs)]; // remove the duplicate entries
    this.picklistChange = true;
  }

  onAircraftPicklistChange(event) {
    this.pickAircraftlistChange = true;
    this.isAircraftButtonDisabled = false;
  }

  onAircraftMoveAllToSource(event) {
    event.items.forEach((ele) => {
      const index = this.selectedAircraftGroupIDs.findIndex(a => a.aircraft_registration == ele.aircraft_registration);
      if (index > -1) {
        this.selectedAircraftGroupIDs.splice(index, 1);
      }
    });
    this.pickAircraftlistChange = true;
    this.isAircraftButtonDisabled = false;
    this.ResetSelectedGroup(false);
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onAircraftMoveAllToTarget(event) {
    this.pickAircraftlistChange = true;
    this.isAircraftButtonDisabled = false;
    event.items.forEach((ele) => {
      if (!this.selectedAircraftGroupIDs.find(a => a.aircraft_registration == ele.aircraft_registration)) {
        this.selectedAircraftGroupIDs.push({ aircraftgroup_id: ele.aircraftgroup_id, aircraft_registration: ele.aircraft_registration, aircraft_id: ele.aircraft_id });
      }
    });
    this.ResetSelectedGroup(true);
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onAircraftMoveToSource(event) {
    this.pickAircraftlistChange = true;
    this.isAircraftButtonDisabled = false;
    event.items.forEach((ele) => {
      const index = this.selectedAircraftGroupIDs.findIndex(a => a.aircraft_registration == ele.aircraft_registration);
      if (index > -1) {
        this.selectedAircraftGroupIDs.splice(index, 1);
      }
    });
    this.ResetSelectedGroup(false);
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onAircraftMoveToTarget(event) {
    event.items.forEach((ele) => {
      if (!this.selectedAircraftGroupIDs.find(a => a.aircraft_registration == ele.aircraft_registration)) {
        this.selectedAircraftGroupIDs.push({ aircraftgroup_id: ele.aircraftgroup_id, aircraft_registration: ele.aircraft_registration, aircraft_id: ele.aircraft_id });
      }
    });
    this.isAircraftButtonDisabled = false;
    this.selectedAircraftGroupIDs = [...new Set(this.selectedAircraftGroupIDs)]; // remove the duplicate entries
    this.pickAircraftlistChange = true;
    this.ResetSelectedGroup(true);
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onPicklistChange(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToSource(event) {
    event.items.forEach((ele) => {
      const index = this.selectedEndPointsIDs.indexOf(ele.endpoint_id);
      if (index > -1) {
        this.selectedEndPointsIDs.splice(index, 1);
      }
    });
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToTarget(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach(ele => {
      this.selectedEndPointsIDs.push(ele.endpoint_id);
    });
  }

  onMoveToSource(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach(ele => {
      const index = this.selectedEndPointsIDs.indexOf(ele.endpoint_id);
      if (index > -1) {
        this.selectedEndPointsIDs.splice(index, 1);
      }
    });
  }

  onRedactionType(e: any) {
    this.clientpackage.redaction_id = e.value;
  }

  loadDataFrames() {
    this.clientpackage.aircrafts = [];
    this.clientpackage.endpoints = [];
    this.conversionType = [];
    this.loadingCount++;
    forkJoin({
      avialableEndPoints: this.datadeliveryEndPointservice.getEndPoints(),
      redactionType: this.enhancedRedactionService.getFligtRedactions(),
      aircraftType: this.commonService.listAircraftFilters(),
      ziptypes: this.flightDataRedactionService.getZipTypes(),
      aircraftgroups: this.aircraftGroupService.listAircraftGroups(),
      getaircraft: this.commonService.listAircraftGroupIdFilters(0)
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.availableEndPoints = [];
          this.availableAircraft = [];
          this.zipType = [];
          this.redactionType = [];

          this.zipType = results.ziptypes;
          results.avialableEndPoints.forEach(ele => {
            this.availableEndPoints.push({ endpoint_id: ele.endpoint_id, endpoint_name: ele.name })
          });

          results.redactionType.forEach(ele => {
            this.redactionType.push({ redaction_id: ele.redaction_id, redaction_type: ele.name })
          });

          this.aircraftGroups = this.teledynePackagesService.populateDropdownItems<AircraftGroup>(results.aircraftgroups, 'aircraftgroup_name', 'aircraftgroup_id');

          results.getaircraft.forEach((ele) => {
            if (!this.availableAircraft.find(a => a.aircraft_registration == ele.aircraft_registration)) {
              this.availableAircraft.push({
                aircraftgroup_id: ele.aircraftgroup_id,
                aircraft_id: ele.aircraft_id,
                aircraft_registration: ele.aircraft_registration,
              });
            }
          });
          this.initialEndpointsCount = 0;
          this.initialAircraftsCount = 0;
          this.clientpackage.file_name = this.file_Name;
          this.availableAircraft.sort((a, b) => { return a.aircraft_registration.localeCompare(b.aircraft_registration) });
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      });
  }



  onCancel() {
    if (this.clientpackageForm.dirty) {
      this.router.navigate([Components.EnhancedPackages.path])
    } else {
      this.router.navigate([Components.EnhancedPackages.path])
    }
  }
  onReset() {
    this.picklistChange = false;
    this.isRedaction = true;
    this.selectedAircraftIDs = [];
    this.selectedEndPointsIDs = [];
    this.availableAircraft = [];
    this.availableEndPoints = [];
    this.conversionType = [];
    this.selectedAircraft = [];
    this.selectedEndPoints = [];
    this.selectedAircraftGroupIDs = [];
    this.targetAircraft = [];
    this.selectedAircraftGroup = null;
    this.selectedTargetAircraftGroup = null;
    this.clientpackageForm.resetForm();
    this.ngOnInit();
    this.isButtonDisabled = true;
  }

  OnSubmit() {
    this.loadingCount++;
    this.selectedAircraftIDs = [];
    this.selectedAircraftGroupIDs.forEach(a => { this.selectedAircraftIDs.push(a.aircraft_id) });
    this.responseModelClientPackages = {
      package_id: this.clientpackage.package_id,
      conversiontype_id: ConversionTypes.FlightData,
      file_name: this.clientpackage.file_name == null ? this.defaultfileName : this.clientpackage.file_name,
      description: this.clientpackage.description,
      name: this.clientpackage.name,
      redaction_id: this.clientpackage.redaction_id,
      predefined_redaction_id: undefined,
      ziptype_id: this.clientpackage.ziptype_id,
      zip_password: this.clientpackage.zip_password == undefined ? "" : this.clientpackage.zip_password,
      aircraft_id: this.selectedAircraftIDs,
      endpoint_id: this.selectedEndPointsIDs,
      organization_id: 0,
      primary: this.clientpackage.primary == undefined ? false : this.clientpackage.primary,
      secondary: this.clientpackage.secondary == undefined ? false : this.clientpackage.secondary,
      enhanced: true
    }
    if (this.isNew) {
      this.enhancedPackagesService.addClientPackage(this.responseModelClientPackages).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: result => {
            this.clientpackage.package_id = result;
            // Update query param
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                packageId: this.clientpackage.package_id,
                name: this.clientpackage.name
              },
              queryParamsHandling: 'merge'
            }).then();

            this.updateBreadcrumb(Components.AddEnhancedPackages.label + ' (' + this.clientpackage.name + ')');
            this.pageTitle = Components.AddEnhancedPackages.label;
            this.picklistChange = false;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.clientpackage.name}`);
            this.isNew = false;
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
            this.clientpackageForm.resetForm(this.clientpackageForm.value);
            this.ngOnInit;
          }
        });
    } else {
      this.enhancedPackagesService.updateClientPackage(this.responseModelClientPackages).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.AddEnhancedPackages.label + ' (' + this.clientpackage.name + ')');
            this.picklistChange = false;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.clientpackage.name}`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.clientpackageForm.resetForm(this.clientpackageForm.value);
            this.ngOnInit;
          }
        });
    }
  }

  onChangeAircraftGroup(selectedAircraftGroup: any) {
    this.selectedAircraftGroup = selectedAircraftGroup.value;
    this.getAircraftValuesBinded(selectedAircraftGroup.value, true)
  }

  onChangeTargetAircraftGroup(selectedAircraftGroup: any) {
    this.selectedTargetAircraftGroup = selectedAircraftGroup.value;
    this.getAircraftValuesBinded(selectedAircraftGroup.value, false)
  }

  getAircraftValuesBinded(selectedValue: number, isSource: boolean) {
    this.loadingCount++;
    this.commonService.listAircraftGroupIdFilters(selectedValue == null ? 0 : selectedValue).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: data => {
          if (isSource) {
            this.availableAircraft = [];
            data.forEach(a => {
              if (a.aircraft_registration != null) {
                if (!this.selectedAircraftGroupIDs.find(ele => ele.aircraft_registration == a.aircraft_registration) && !this.availableAircraft.find(ele => ele.aircraft_registration == a.aircraft_registration)) {
                  this.availableAircraft.push({ aircraft_registration: a.aircraft_registration, aircraftgroup_id: selectedValue, aircraft_id: a.aircraft_id })
                }
              }
            })
          } else {
            if (selectedValue == null) {
              this.targetAircraft = this.selectedAircraftGroupIDs;
            } else {
              this.targetAircraft = this.selectedAircraftGroupIDs.filter(a => data.find(i => i.aircraft_registration == a.aircraft_registration));
            }
          }
        },
        error: err => {
          this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () =>
          this.loadingCount--
      });
  }

  ResetSelectedGroup(isTarget: boolean) {
    const getFilterSelection = Array.from(new Set(this.selectedAircraftGroupIDs.map(a => a.aircraftgroup_id)));
    if (isTarget) {
      if (getFilterSelection.length > 1) {
        this.selectedTargetAircraftGroup = null;
      } else {
        this.selectedTargetAircraftGroup = this.selectedAircraftGroup;
      }
      this.getAircraftValuesBinded(this.selectedTargetAircraftGroup, false)
    } else {
      const getSourceSelection = Array.from(new Set(this.availableAircraft.map(a => a.aircraftgroup_id)));
      if (getSourceSelection.length > 1) {
        this.selectedAircraftGroup = null;
        this.getAircraftValuesBinded(this.selectedAircraftGroup, true)
      } else {
        this.selectedAircraftGroup = this.selectedTargetAircraftGroup;
      }
      this.getAircraftValuesBinded(this.selectedAircraftGroup, true)
    }
  }

}
