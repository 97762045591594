export const permissions = {
  endpointData: {
    create: 'endpoint_data_delivery.endpoints.create',
    manage: 'endpoint_data_delivery.endpoints.manage',
    delete: 'endpoint_data_delivery.endpoints.delete',
    view: 'endpoint_data_delivery.endpoints.view',
  },
  datadelivery_flight_redaction: {
    create: 'data_delivery.flight_data_redactions.create',
    manage: 'data_delivery.flight_data_redactions.manage',
    delete: 'data_delivery.flight_data_redactions.delete',
    view: 'data_delivery.flight_data_redactions.view',
  },
  sarRedactionsData: {
    create: 'endpoint_data_delivery.sar_redactions.create',
    manage: 'endpoint_data_delivery.sar_redactions.manage',
    delete: 'endpoint_data_delivery.sar_redactions.delete',
    view: 'endpoint_data_delivery.sar_redactions.view',
  },
  clientpackagesData: {
    create: 'endpoint_data_delivery.client_packages.create',
    manage: 'endpoint_data_delivery.client_packages.manage',
    delete: 'endpoint_data_delivery.client_packages.delete',
    view: 'endpoint_data_delivery.client_packages.view',
  },
  endpointdatadelivery_flight_redaction: {
    view: 'endpoint_data_delivery.flight_data_redactions.view',
  },
  endpointdatadelivery_acms_redaction: {
    view: 'endpoint_data_delivery.acms_report_redactions.view',
  },
  endpointdatadelivery_sar_redaction: {
    view: 'endpoint_data_delivery.sar_redactions.view',
  },
  endpointdatadelivery_client_packages: {
    view: 'endpoint_data_delivery.client_packages.view',
  },
  endpointdatadelivery_roles: {
    create: 'endpoint_data_delivery.endpoint_data_delivery_roles.create',
    manage: 'endpoint_data_delivery.endpoint_data_delivery_roles.manage',
    delete: 'endpoint_data_delivery.endpoint_data_delivery_roles.delete',
    view: 'endpoint_data_delivery.endpoint_data_delivery_roles.view',
  },
  endpointdatadelivery_users: {
    manage: 'endpoint_data_delivery.endpoint_data_delivery_users.manage',
    view: 'endpoint_data_delivery.endpoint_data_delivery_users.view',
  },
  endpoint: {
    view: 'endpoint_data_delivery.endpoints.view',
  },
  acmsredactionData: {
    create: 'endpoint_data_delivery.acms_report_redactions.create',
    manage: 'endpoint_data_delivery.acms_report_redactions.manage',
    delete: 'endpoint_data_delivery.acms_report_redactions.delete',
    view: 'endpoint_data_delivery.acms_report_redactions.view',
  },
};
