import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import {
  ClientPackage,
  ClientPackages,
} from 'src/app/features/datadelivery/datadelivery/models/ClientPackage';
import { EndpointClientPackagesService } from '../../../services/clientpackages.service';
import { Components } from '../../../integration/endpointdatadelivery.components';

@Component({
  selector: 'app-clientpackage-data-list',
  templateUrl: './clientpackage-data-list.component.html',
  styleUrls: ['./clientpackage-data-list.component.css'],
})
export class ClientpackageDataListComponent
  extends BaseViewComponent
  implements OnInit {
  pageTitle = Components.ClientpackagesDataDelivery.label;
  cols: any[] = [];
  clientpackages: ClientPackages[] = [];
  clientpackage!: ClientPackage;
  messageLabel = 'Client Package';
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private endpointClientpackageservice: EndpointClientPackagesService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.ClientpackagesDataDelivery.label },
    ]);
  }

  ngOnInit() {
    this.getBreadCrums();
    this.intialVal();
  }

  intialVal() {
    this.clientpackage = new ClientPackage();
    this.loadClientPackages();
  }

  loadClientPackages() {
    this.loadingCount++;
    forkJoin({
      getclientpackages: this.endpointClientpackageservice.getClientPackages(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.clientpackages = results.getclientpackages;
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.clientpackages = [];
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }
  onRowSelect(event) {
    this.ngZone.run(() => {
      this.router.navigate([Components.ViewClientpackagesDataDelivery.path], {
        queryParams: {
          packageId: event.data.package_id,
          name: event.data.name,
        },
      });
    });
  }
}
