import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {
  ClientPackages,
  ConversionType,
  EndPoints,
  Redaction,
  ZipType,
  aircrafts,
} from 'src/app/features/datadelivery/datadelivery/models/ClientPackage';

@Component({
  selector: 'app-clientpackage-form',
  templateUrl: './clientpackage-form.component.html',
})
export class ClientpackageFormComponent implements OnInit {
  @Input() clientpackage: ClientPackages;
  @Input() redactionType: Redaction[];
  @Input() zipType: ZipType[];
  @Input() conversionType: ConversionType[];
  @Input() availableEndPoints: EndPoints[];
  @Input() availableAircraft: aircrafts[];
  @Input() selectedEndPoints: EndPoints[];
  @Input() selectedAircraft: aircrafts[];
  @Input() selectedAircraftGroup: number;
  @Input() selectedEndPointsIDs: number[];
  @Input() selectedAircraftIDs: number[];
  @Input() isButtonDisabled: boolean;
  @Input() isAircraftButtonDisabled: boolean;
  @Input() pickListChange: boolean;
  @Input() isRedaction: boolean;
  @Input() isReadonly: boolean;
  @Input() showButtons: boolean;
  @Input() selectedRedactionId: number;
  @Input() aircraftGroups: SelectItem[];
  @Input() targetAircraft: aircrafts[];
  @Input() selectedTargetAircraftGroup: number;
  @Input() isNew: boolean;
  @Output() moveToTarget = new EventEmitter<{ items: EndPoints[] }>();
  @Output() moveToSource = new EventEmitter<{ items: EndPoints[] }>();
  @Output() moveAllToSource = new EventEmitter<{ items: EndPoints[] }>();
  @Output() moveAllToTarget = new EventEmitter<{ items: EndPoints[] }>();
  @Output() conversionTypeChange = new EventEmitter<{ value: number }>();
  @Output() redactionTypeChange = new EventEmitter<{ value: number }>();
  @Output() zipSelectionChange = new EventEmitter<{ value: number }>();
  @Output() aircraftMoveToTarget = new EventEmitter<{ items: aircrafts[] }>();
  @Output() aircraftMoveToSource = new EventEmitter<{ items: aircrafts[] }>();
  @Output() aircraftMoveAllToSource = new EventEmitter<{
    items: aircrafts[];
  }>();
  @Output() aircraftMoveAllToTarget = new EventEmitter<{
    items: aircrafts[];
  }>();
  @Output() changeAircraftGroup = new EventEmitter<SelectItem>();
  @Output() changeTargetAircraftGroup = new EventEmitter<SelectItem>();

  @Output() submitForm = new EventEmitter<ClientPackages>();
  @Output() cancelForm = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  @ViewChild('clientpackageForm')
  clientpackageForm!: NgForm;
  @ViewChild('panel') panel;

  constructor() {
    // Intentionally empty
  }

  ngOnInit(): void {
    // Intentionally empty
  }

  onSubmit() {
    this.submitForm.emit(this.clientpackage);
  }

  onCancel() {
    this.cancelForm.emit();
  }

  onReset() {
    this.clientpackageForm.resetForm();
    this.resetForm.emit();
  }

  onMoveToTarget(event: { items: EndPoints[] }) {
    this.moveToTarget.emit(event);
    this.clientpackageForm.form.markAsDirty();
  }

  onMoveToSource(event: { items: EndPoints[] }) {
    this.moveToSource.emit(event);
    this.clientpackageForm.form.markAsDirty();
  }

  onMoveAllToSource(event: { items: EndPoints[] }) {
    this.moveAllToSource.emit(event);
    this.clientpackageForm.form.markAsDirty();
  }

  onMoveAllToTarget(event: { items: EndPoints[] }) {
    this.moveAllToTarget.emit(event);
    this.clientpackageForm.form.markAsDirty();
  }

  onConversionType(event: { value: number }) {
    this.conversionTypeChange.emit(event);
  }

  onRedactionType(event: { value: number }) {
    this.redactionTypeChange.emit(event);
  }

  onZipSelection(event: { value: number }) {
    this.zipSelectionChange.emit(event);
  }

  onAircraftMoveToTarget(event: { items: aircrafts[] }) {
    this.aircraftMoveToTarget.emit(event);
  }

  onAircraftMoveToSource(event: { items: aircrafts[] }) {
    this.aircraftMoveToSource.emit(event);
    this.clientpackageForm.form.markAsDirty();
  }

  onAircraftMoveAllToSource(event: { items: aircrafts[] }) {
    this.aircraftMoveAllToSource.emit(event);
    this.clientpackageForm.form.markAsDirty();
  }

  onAircraftMoveAllToTarget(event: { items: aircrafts[] }) {
    this.aircraftMoveAllToTarget.emit(event);
    this.clientpackageForm.form.markAsDirty();
  }

  onChangeAircraftGroup(event: SelectItem) {
    this.changeAircraftGroup.emit(event);
    this.clientpackageForm.form.markAsDirty();
  }

  onChangeTargetAircraftGroup(event: SelectItem) {
    this.changeTargetAircraftGroup.emit(event);
  }
}
