<app-spinner [isLoading]='isLoading'></app-spinner>
<div class="col-12 card">
  <div class="details">

    <div class="card-header m-2">
      <h5 class="title">{{dataFrameAnalyse?.label}}</h5>

    </div>

    <div *ngIf="errorMessages?.length" class="card">
      <h5 class="header">Data Frame Details</h5>
      <ul class="details-list">
        <li *ngFor="let error of errorMessages">{{error}}</li>
      </ul>
    </div>
  </div>
  <div class="card">
    <h5 class="header">Parameters</h5>
    <p-table [value]="errors" [filterDelay]="0" [tableStyle]="{'width':'100%'}" #dt dataKey="PRM_MNEMONIC"
      [totalRecords]="errors.length" selectionMode="single" [showCurrentPageReport]="true"
      [paginator]="errors.length > defaultTableRows" [rowsPerPageOptions]="DataFrameConstants.rowsPerPageOptions"
      [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' items'}}" sortField="PRM_MNEMONIC"
      [sortOrder]="1" [responsive]="true" (onRowSelect)="navigateToParameter($event)">
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.type">
              <th *ngSwitchDefault [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [hidden]="!col.sortable" [field]="col.field"></p-sortIcon>
                <p-columnFilter [hidden]="!col.sortable" [id]="col.field" [attr.name]="col.field" type="text"
                  [showOperator]="false" matchMode="contains" class="ml-auto" [field]="col.field" display="menu"
                  [showAddButton]="false">
                </p-columnFilter>
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr [pSelectableRow]="data" class="p-selectable-row">
          <ng-container *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.type">
              <td *ngSwitchDefault>
                {{data[col.field]}}
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="3">
            No Data Frame Errors Found.
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="flex justify-content-end">
      <span class="p-input-icon-left">
        <span class="p-input-icon-left">
          <button pRipple pButton label="Back" class="p-button-text" icon="pi pi-times" type="button"
            (click)="returnToPreviousPage()"></button>
        </span>
      </span>
    </div>
  </div>
</div>
