import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './platform/components/page-not-found.component';
import {AccessDeniedComponent} from './platform/components/access-denied.component';
import {PreloadSelectedModulesList} from './platform/strategies/preload-strategy';
import {MainComponent} from 'src/app/platform/components/main/main.component';
import {MenuService} from './common/services/menu.service';
import { AuthGuard } from './security/guards/auth.guard';

const routes: Routes = [
  {
    // NOTE: The AppMainComponent (contains app-main) must be the root of all components expected to be
    // displayed in the main layout (topbar, menu, footer, etc).
    path: '', component: MainComponent,
    children: [
      // Platform / Common
      {
        path: '',
        loadChildren: () => import('src/app/common/components/home/home.module').then(m => m.HomeModule),
        data: { preload: true},
        canActivate: [AuthGuard]
      },
      {
        path: MenuService.Login.Link,
        loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
        data: { preload: true}
      },
      // Administration
      {
        path: '',
        loadChildren: () => import('./features/administration/administration.module').then(m => m.AdministrationModule),
        data: { preload: false},
        canActivate: [AuthGuard]
      },
      // Configuration
      {
        path: '',
        loadChildren: () => import('./features/configuration/configuration.module').then(m => m.ConfigurationModule),
        data: { preload: false},
        canActivate: [AuthGuard]
      },
      // Common roles related...
      {
        path: MenuService.UsersAdmin.Link,
        loadChildren: () => import('./common/components/user-administration/user-administration.module').then(m => m.UserAdministrationModule),
        data: {preload: false},
        canActivate: [AuthGuard]
      },
      {
        path: MenuService.RolesAdmin.Link,
        loadChildren: () => import('./common/components/roles/roles.module').then(m => m.RolesModule),
        data: {preload: false},
        canActivate: [AuthGuard]
      },
      {
        path: MenuService.RoleDetailsAdmin.Link,
        loadChildren: () => import('./common/components/role-details/role-details.module').then(m => m.RoleDetailsModule),
        data: { preload: false },
        canActivate: [AuthGuard]
      },
      //DataDelivery
      {
        path: '',
        loadChildren: () => import('./features/datadelivery/datadelivery/datadelivery.module').then(m => m.DatadeliveryModule),
        data: { preload: false },
        canActivate: [AuthGuard]
       },
       {
        path: '',
        loadChildren: () =>
          import(
            './features/datadelivery/endpointdatadelivery/endpointdatadelivery.module'
          ).then((m) => m.EndpointDataDeliveryModule),
        data: { preload: false },
        canActivate: [AuthGuard],
      },
       //SendtoTeledyne
       {
        path: '',
        loadChildren: () => import('./features/sendtoteledyne/sendtoteledyne.module').then(m => m.SendtoTeledyneModule),
        data: { preload: false },
        canActivate: [AuthGuard]
      },

      {
        path: '',
        loadChildren: () => import('./common/components/user-notifications/user-notifications.module').then(m => m.UserNotificationsModule),
        data: { preload: false },
        canActivate: [AuthGuard]
      },

    ]
  },
  // {path: 'login', component: LoginComponent},
  // {path: 'forgot-password', component: ForgotPasswordComponent},
  // {path: 'reset-password/:token', component: ResetPasswordComponent},
  {path: 'access-denied', component: AccessDeniedComponent},
  {path: '**', pathMatch: 'full', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadSelectedModulesList,
    //useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
  providers: [PreloadSelectedModulesList, AuthGuard]
})

export class AppRoutingModule {
}
