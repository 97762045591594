import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { permissions } from '../../security/models/permissions';
import { FeatureHeader, FeatureRoot, FeatureUserManagementConfig } from '../models/feature-item.model';
import { MenuItem } from '../models/menuItem';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { UserNotificationsComponent } from '../components/user-notifications/user-notifications.component';
import { UserDetailsComponent } from 'src/app/features/administration/components/user-details/user-details.component';
import { Components } from 'src/app/features/administration/integration/administration.components';

export interface ComponentLink {
  Label: string;
  Link: string;
  Permission?: string;
  Icon?: string;
}

@Injectable()
export class MenuService {

  private static featureRoot: FeatureRoot[] = [];
  private readonly maxRecursionDepth = 5;
  private permissions: string[] = [];

  constructor(
    private userService: SecurityUserService
  ) {
    this.userService.userInfo.subscribe(userInfo => { this.permissions = userInfo.permissions;});
  }

  public static loadFeatures(featureRoot: FeatureRoot[]) {
    MenuService.featureRoot = featureRoot;
  }

  public getFeatures() {
    return MenuService.featureRoot;
  }

  public buildMainMenu(): MenuItem[] {
    const mainMenuMultiRoot = []
    MenuService.featureRoot.forEach(feature => {
      const existingUserItem = feature.items.find(item => item?.label === "User");
      if (!existingUserItem && this.hasViewPermission(feature.label)) {
        feature.items.push({
          label: "User",
          icon: "person",
          items: [
            //Hide user notificatios for now
            //Components.UserNotifications,
            Components.UserProfile
          ]
        });
      }
      mainMenuMultiRoot.push(this.buildMenuBranch(feature));
      if (feature.user_management)
        mainMenuMultiRoot[mainMenuMultiRoot.length - 1].items.push(this.buildUserManagement(feature.user_management))
    })
    return mainMenuMultiRoot;
  }

  private buildUserManagement(config: FeatureUserManagementConfig): MenuItem {

    const node = {
      label: 'User Permissions',
      icon: 'manage_accounts',
      id: 'mnu-securityManagement',
      items: [
        {
          label: 'Users',
          icon: 'person_outline',
          routerLink: [MenuService.UsersAdmin.Link],
          id: 'mnu-' + MenuService.UsersAdmin.Link,
          queryParams: { app: config.feature_name },
          visible: this.userService.userHasPermission(config.user_permission)
        },
        {
          label: 'Roles',
          icon: 'people_alt',
          routerLink: [MenuService.RolesAdmin.Link],
          id: 'mnu-' + MenuService.RolesAdmin.Link,
          queryParams: { app: config.feature_name },
          visible: this.userService.userHasPermission(config.roles_permission)
        }
      ],
      visible: true
    }

    node.visible = node.items.findIndex(i => i.visible) !== -1;
    return node;
  }

  private buildMenuBranch(root: FeatureHeader, recursionDepth = 0): MenuItem {
    const items = [];
    if (root && root.items && Array.isArray(root.items)) {
      root.items.forEach(item => {
        if (item.path) {
          items.push({
            label: item.label,
            icon: item.icon,
            routerLink: [item.path],
            id: this.getMenuIdFromLabel(item.label),
            visible: item.permission === undefined || this.userService.userHasPermission(item.permission)
          })
        } else {
          // sub-header
          if (recursionDepth < this.maxRecursionDepth)
            items.push(this.buildMenuBranch(item, recursionDepth + 1));
        }
      });
    }

    return {
      label: root.label,
      icon: root.icon,
      id: this.getMenuIdFromLabel(root.label),
      items: items,
      visible: items.findIndex(i => i.visible) !== -1
    }
  }

    // Common menu options should only visible if user has permission to view that application
    private hasViewPermission(featureName: string): boolean {
      const patterns = {
        "Administration": /^administration\.[^.]+\.view$/,
        "Aircraft Configuration": /^configuration\.[^.]+\.view$/,
        "Data Delivery": /^data_delivery\.[^.]+\.view$/,
        "Send to Teledyne": /^send_to_teledyne\.[^.]+\.view$/,
        "Endpoint Data Delivery": /^endpoint_data_delivery\.[^.]+\.view$/
      };
  
      const pattern = patterns[featureName];
      return pattern ? this.permissions.some(p => pattern.test(p)) : false;
    }

  private getMenuIdFromLabel(label: string): string {
    return 'mnu-' + label.toLowerCase().replace(' ', '-');
  }

  // ARO All code below here will get moved / deleted as features are refactored

  // Infrastructure

  public static readonly Dashboard: ComponentLink = {
    'Label': 'Home',
    'Link': '/',
    'Permission': undefined
  }

  public static readonly Login: ComponentLink = {
    'Label': 'Login',
    'Link': 'login',
    'Permission': undefined
  }

  public static readonly AccessDenied: ComponentLink = {
    'Label': 'Access Denied',
    'Link': 'access-denied',
    'Permission': undefined
  }

  public static readonly Errors: ComponentLink = {
    'Label': 'Application Errors',
    'Link': 'errors',
    'Permission': undefined
  }

  public static readonly UsersAdmin: ComponentLink = {
    'Label': 'User Roles',
    'Link': 'users-administration',
    'Permission': undefined
  }

  public static readonly UserDetailsAdmin: ComponentLink = {
    'Label': 'User Details',
    'Link': 'user-details-administration',
    'Permission': permissions.admin.users.manage //TDY ONLY
  }

  public static readonly RolesAdmin: ComponentLink = {
    'Label': 'Roles',
    'Link': 'roles-administration',
    'Permission': undefined
  }

  public static readonly RoleDetailsAdmin: ComponentLink = {
    'Label': 'Role Details',
    'Link': 'role-details-administration',
    'Permission': undefined
  }

  // DDS

  public static readonly DDSTest: ComponentLink = {
    'Label': 'Test',
    'Link': 'dds-test',
    'Permission': undefined,
    'Icon': 'quiz'
  }

  // Send to Teledyne

  public static readonly SendToTeledyneTest: ComponentLink = {
    'Label': 'Test',
    'Link': 'send-to-teledyne-test',
    'Permission': undefined,
    'Icon': 'quiz'
  }

  //End

  public static readonly Endpoints: ComponentLink = {
    'Label': 'Endpoints',
    'Link': 'endpoints',
    'Icon': 'send',
    'Permission': undefined
  }

  public static readonly AddEndpoints: ComponentLink = {
    'Label': 'Add Endpoints',
    'Link': 'addendpoints',
    'Permission': undefined
  }

  public static readonly DataRedactions: ComponentLink = {
    'Label': 'Flight Data Redactions',
    'Link': 'dataredactions',
    'Icon': 'flight',
    'Permission': undefined
  }

  public static readonly AddDataRedactions: ComponentLink = {
    'Label': 'Add Data Redactions',
    'Link': 'adddataredactions',
    'Permission': undefined
  }

  public static readonly ACMSReportRedactions: ComponentLink = {
    'Label': 'ACMS Report Redactions',
    'Link': 'acmsreportredactions',
    'Permission': undefined
  }

  public static readonly ACMSReportAddRedactions: ComponentLink = {
    'Label': 'Add ACMS Report Redactions',
    'Link': 'acmsreportaddredactions',
    'Permission': undefined
  }

  public static readonly SARRedactions: ComponentLink = {
    'Label': 'SAR Redactions',
    'Link': 'sarredactions',
    'Permission': undefined
  }

  public static readonly AddSARRedactions: ComponentLink = {
    'Label': 'Add SAR Redactions',
    'Link': 'saraddredactions',
    'Permission': undefined
  }

  public static readonly ClientPackages: ComponentLink = {
    'Label': 'Client Packages',
    'Link': 'clientpackages',
    'Permission': undefined
  }

  public static readonly AddClientPackages: ComponentLink = {
    'Label': 'Add Client Packages',
    'Link': 'addclientpackages',
    'Permission': undefined
  }

  public static readonly FlightDataRedactions: ComponentLink = {
    'Label': 'Flight Data Redactions',
    'Link': 'flightdataredactions',
    'Icon': 'flight',
    'Permission': undefined
  }

  public static readonly AddFlightDataRedactions: ComponentLink = {
    'Label': 'Add Flight Data Redactions',
    'Link': 'addflightdataredactions',
    'Permission': undefined
  }

  public static readonly ClientApplication: ComponentLink = {
    'Label': 'Client Authentication',
    'Link': 'clientapplication',
    'Permission': undefined
  }
  public static readonly AddClientApplication: ComponentLink = {
    'Label': 'Add Client Authentication',
    'Link': 'addclientapplication',
    'Permission': undefined
  }
  public static readonly TeledynePackages: ComponentLink = {
    'Label': 'Teledyne Packages',
    'Link': 'teledynepackages',
    'Permission': undefined
  }

  public static readonly AddTeledynePackages: ComponentLink = {
    'Label': 'Add Teledyne Packages',
    'Link': 'addteledynepackages',
    'Permission': undefined
  }

  public static readonly SendToTeledyne: ComponentLink = {
    'Label': 'Send to Teledyne',
    'Link': 'sendtoteledyne',
    'Permission': undefined
  }

  public static readonly AddSendToTeledyne: ComponentLink = {
    'Label': 'Add to Teledyne',
    'Link': 'addsendtoteledyne',
    'Permission': undefined
  }

  public static readonly TeledyneEndPoint: ComponentLink = {
    'Label': 'Teledyne Endpoints',
    'Link': 'teledyneendpoints',
    'Permission': undefined
  }

  public static readonly TeledyneAddEndPoint: ComponentLink = {
    'Label': 'Add Endpoints',
    'Link': 'addteledyneendpoints',
    'Permission': undefined
  }


  public static readonly AirportInformationList: ComponentLink = {
    'Label': 'Airport Information',
    'Link': 'airportinformationlist',
    'Permission': undefined
  }
  public static readonly AirportInformationDetails: ComponentLink = {
    'Label': 'Airport Information Details',
    'Link': 'airportinformationdetails',
    'Permission': undefined
  }
  public static readonly RunwayInformationList: ComponentLink = {
    'Label': 'Runway Information',
    'Link': 'runwayinformationlist',
    'Permission': undefined
  }
  public static readonly RunwayInformationDetails: ComponentLink = {
    'Label': 'Runway Information Details',
    'Link': 'runwayinfodetails',
    'Permission': undefined
  }
  // ARO: old menu items still plumbed into the ported ACES code.
  // Not moved over to new structure because they are not used in the current code.

  public static readonly AircraftStatusLabel = 'Aircraft';
  public static readonly AircraftStatusLink = 'aqi-by-tail';

  public static readonly NotificationEventsLabel = 'User Notifications';
  public static readonly NotificationEventsLink = 'notifications';

  public static readonly AuditEventsLabel = 'Audit Log';
  public static readonly AuditEventsLink = 'audit-log';

  public static readonly ErrorsLabel = 'Application Errors';
  public static readonly ErrorsLink = 'errors';

  private menuSource = new Subject<string>();
  menuSource$ = this.menuSource.asObservable();
  private resetSource = new Subject();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(key: string) {
    this.menuSource.next(key);
  }

  reset() {
    this.resetSource.next(null);
  }
}
